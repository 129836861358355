export function DraftIssuedIcon({ className = '' }) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none" className={className}>
      <path
        d="M24 47C36.7025 47 47 36.7025 47 24C47 11.2975 36.7025 1 24 1C11.2975 1 1 11.2975 1 24C1 36.7025 11.2975 47 24 47Z"
        fill="white"
        stroke="#999999"
        strokeWidth={2}
      />
      <path
        d="M24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42Z"
        fill="#999999"
      />
      <g clipPath="url(#clip0_1263_4240)">
        <path
          d="M26.2214 27.9157L25.7466 31.8464C25.7272 31.9513 25.7419 32.0597 25.7885 32.1557C25.8352 32.2517 25.9113 32.3302 26.0058 32.3797C26.1003 32.4293 26.2082 32.4473 26.3137 32.4311C26.4191 32.4149 26.5166 32.3653 26.5919 32.2897L27.9591 30.9232L29.3264 32.2897C29.4018 32.3651 29.4993 32.4145 29.6047 32.4306C29.7101 32.4467 29.8179 32.4287 29.9124 32.3792C30.0069 32.3297 30.083 32.2513 30.1298 32.1555C30.1766 32.0596 30.1915 31.9513 30.1724 31.8464L29.6916 27.8887"
          stroke="white"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.125 25.6875C25.125 26.0568 25.1977 26.4226 25.3391 26.7638C25.4804 27.105 25.6876 27.4151 25.9488 27.6762C26.2099 27.9374 26.52 28.1446 26.8612 28.2859C27.2024 28.4273 27.5682 28.5 27.9375 28.5C28.3068 28.5 28.6726 28.4273 29.0138 28.2859C29.355 28.1446 29.6651 27.9374 29.9262 27.6762C30.1874 27.4151 30.3946 27.105 30.5359 26.7638C30.6773 26.4226 30.75 26.0568 30.75 25.6875C30.75 25.3182 30.6773 24.9524 30.5359 24.6112C30.3946 24.27 30.1874 23.9599 29.9262 23.6988C29.6651 23.4376 29.355 23.2304 29.0138 23.0891C28.6726 22.9477 28.3068 22.875 27.9375 22.875C27.5682 22.875 27.2024 22.9477 26.8612 23.0891C26.52 23.2304 26.2099 23.4376 25.9488 23.6988C25.6876 23.9599 25.4804 24.27 25.3391 24.6112C25.1977 24.9524 25.125 25.3182 25.125 25.6875Z"
          stroke="white"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.875 30.1875H18.375C18.0766 30.1875 17.7905 30.069 17.5795 29.858C17.3685 29.647 17.25 29.3609 17.25 29.0625V16.6875C17.25 16.3891 17.3685 16.103 17.5795 15.892C17.7905 15.681 18.0766 15.5625 18.375 15.5625H26.3467C26.6449 15.5626 26.9309 15.681 27.1417 15.8918L29.2958 18.0457C29.5065 18.2566 29.6249 18.5426 29.625 18.8407V21.1875"
          stroke="white"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1263_4240">
          <rect width={18} height={18} fill="white" transform="translate(15 15)" />
        </clipPath>
      </defs>
    </svg>
  )
}
