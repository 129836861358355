import { useTranslation } from 'react-i18next'
import { GetUserRoleModes } from '@inphiz-shared/shared-models/src/F_Selfleadership/Constants/get-user-role-modes'
import { useMemo, useState } from 'react'
import FlatList from 'flatlist-react'
import { BorderButton, CustomAlert, CustomButton, CustomText, Spinner } from '../../atoms'
import { CloseIcon, SearchIcon } from '../../../icons'
import type { UserJobRoleItem } from '../../../packages/api-web/Employee'
import { useAllJobRoles, usePostJobRoleOffer } from '../../../packages/api-web/Employee'

interface GroupMembersProps {
  handle?: string
  onCloseAlert?: () => void
  selectedItem?: UserJobRoleItem
}

export function UnlockJobModal({ onCloseAlert, handle, selectedItem }: GroupMembersProps) {
  const { t } = useTranslation('common')
  const [searchTxt, setSearchTxt] = useState<string>('')
  const { isLoading: isAllJobRoleLoad, data: jobRolesData } = useAllJobRoles({
    userHandle: handle,
    mode: GetUserRoleModes.toUnlock,
  })
  const { isPending: isOfferPending, mutate, variables } = usePostJobRoleOffer()

  const jobRoles = useMemo(() => {
    let result = selectedItem
      ? jobRolesData?.items?.filter(i => i?.jobRole?.handle == selectedItem?.jobRole?.handle)
      : jobRolesData?.items
    if (searchTxt?.length > 3) {
      result = jobRolesData?.items?.filter(i =>
        i?.jobRole?.title?.toLowerCase()?.includes(searchTxt),
      )
    }
    return result
  }, [jobRolesData, searchTxt, selectedItem])

  const renderItem = (item: UserJobRoleItem) => {
    return (
      <div className="flex items-center gap-3 mt-5">
        <CustomText variant="mdPoppinsRegular" className="flex-1">
          {item?.jobRole?.title ?? ''}
        </CustomText>
        <BorderButton
          children={
            !variables?.forceAssignmentWithoutApproval
            && variables?.jobRoleHandles?.includes(`${item?.jobRole?.handle}`)
              ? isOfferPending
                ? 'Loading...'
                : 'Sent'
              : 'Offer'
          }
          className={`px-7 ${!variables?.forceAssignmentWithoutApproval && variables?.jobRoleHandles?.includes(`${item?.jobRole?.handle}`) ? 'bg-draft' : ''}`}
          onClick={() => {
            assignJob(false, item?.jobRole?.handle ?? '')
          }}
        />
        <BorderButton
          children={
            variables?.forceAssignmentWithoutApproval
            && variables?.jobRoleHandles?.includes(`${item?.jobRole?.handle}`)
              ? isOfferPending
                ? 'Loading...'
                : 'Sent'
              : 'Assign'
          }
          className={`px-7 ${variables?.forceAssignmentWithoutApproval && variables?.jobRoleHandles?.includes(`${item?.jobRole?.handle}`) ? 'bg-draft' : ''}`}
          onClick={() => {
            assignJob(true, item?.jobRole?.handle ?? '')
          }}
        />
      </div>
    )
  }
  const assignJob = (isAssign: boolean, jobRoleHandle: string) => {
    const data = {
      userHandle: handle,
      jobRoleHandles: [jobRoleHandle],
      forceAssignmentWithoutApproval: isAssign,
    }
    mutate(data, {
      onSuccess: () => {
        onCloseAlert?.()
      },
    })
  }

  if (isAllJobRoleLoad)
    return <Spinner />

  return (
    <CustomAlert
      onClose={() => {
        onCloseAlert?.()
      }}
    >
      <div className="flex items-center justify-center">
        <CustomText variant="xlPoppinsSemiBold" className="flex-1">
          {t('unlockJobRoles')}
        </CustomText>
        <button onClick={() => onCloseAlert?.()}>
          <CloseIcon />
        </button>
      </div>
      <div className="mt-5 flex flex-col text-left gap-4 py-5 border-y border-stroke">
        {!selectedItem && (
          <>
            <CustomText variant="mdPoppinsRegular" className="flex-1">
              {t('jobRolesUnlock')}
            </CustomText>
            <div className="flex items-center w-auto gap-4 h-10 border border-stroke px-3 rounded-md">
              <SearchIcon />
              <input
                type="text"
                value={searchTxt}
                placeholder={t('searchPlaceholder')}
                className="outline-0 flex-1 min-w-[100px]"
                onChange={e => setSearchTxt(e.target.value)}
              />
              {searchTxt && (
                <button
                  onClick={() => {
                    setSearchTxt('')
                  }}
                >
                  <CloseIcon className="h-5" />
                </button>
              )}
            </div>
          </>
        )}
        <div className="max-h-96 overflow-hidden overflow-y-auto">
          <FlatList
            list={jobRoles ?? []}
            renderItem={renderItem}
            renderWhenEmpty={() => {
              return (
                <div className="h-10 flex flex-1 items-center justify-center">
                  <CustomText variant="mdPoppinsRegular">{t('noDataFound')}</CustomText>
                </div>
              )
            }}
          />
        </div>
      </div>
      <CustomButton
        children={t('done')}
        backgroundColor="bg-secondary"
        textColor="text-textWhite"
        className="!px-5 float-end mt-5"
        onClick={() => {
          onCloseAlert?.()
        }}
      />
    </CustomAlert>
  )
}
