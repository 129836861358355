export function Policy({ className = '' }) {
  return (
    <svg width={25} height={25} viewBox="0 0 25 25" className={className}>
      <defs>
        <clipPath id="clip-Policies">
          <rect width={25} height={25} />
        </clipPath>
      </defs>
      <g id="Policies" clipPath="url(#clip-Policies)">
        <g
          id="Interface-Essential_Form-Edition_task-checklist-check"
          data-name="Interface-Essential / Form-Edition / task-checklist-check"
          transform="translate(-494.506 -4186.5)"
        >
          <g id="Group_380" data-name="Group 380">
            <g id="task-checklist-check">
              <path
                id="Shape_1718"
                data-name="Shape 1718"
                d="M515.257,4196v-3.751a1.5,1.5,0,0,0-1.5-1.5h-4"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
              />
              <path
                id="Shape_1719"
                data-name="Shape 1719"
                d="M501.257,4190.75h-4a1.5,1.5,0,0,0-1.5,1.5v16.5a1.5,1.5,0,0,0,1.5,1.5h8.248"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
              />
              <path
                id="Shape_1720"
                data-name="Shape 1720"
                d="M509.428,4191.737a.753.753,0,0,1-.711.513H502.3a.751.751,0,0,1-.712-.513l-1-3a.748.748,0,0,1,.712-.987h8.419a.749.749,0,0,1,.711.987Z"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
              />
              <path
                id="Shape_1721"
                data-name="Shape 1721"
                d="M503.257,4207.25h-3.752a.75.75,0,0,1-.75-.75v-12.75"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
              />
              <path
                id="Shape_1722"
                data-name="Shape 1722"
                d="M512.257,4193.75v1.5"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
              />
              <path
                id="Oval_225"
                data-name="Oval 225"
                d="M512.255,4210.25a6,6,0,1,0-6-6A6,6,0,0,0,512.255,4210.25Z"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
              />
              <path
                id="Shape_1723"
                data-name="Shape 1723"
                d="M514.929,4202.5l-2.905,3.874a.751.751,0,0,1-1.131.08l-1.5-1.5"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
              />
              <path
                id="Shape_1724"
                data-name="Shape 1724"
                d="M501.757,4196.75h4.5"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
              />
              <path
                id="Shape_1725"
                data-name="Shape 1725"
                d="M501.757,4201.25h1.5"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
