import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst, QueryKey } from '@inphiz/api'
import type { RemoveRoleApiRequest } from '@inphiz-shared/shared-models/src/F_Selfleadership/remove-role-api-request'
import { GetUserRoleModes } from '@inphiz-shared/shared-models/src/F_Selfleadership/Constants/get-user-role-modes'
import { QueryBuilder } from '@inphiz/core-utilities'
import { queryClient } from '../../../App'

export function useDeleteOfferJobRole() {
  return useMutation({
    mutationFn: async (params: RemoveRoleApiRequest) => {
      const qb = new QueryBuilder(APIConst.employeesPerformance.jobRolesRemove)
      const result = await APIFunctions.DELETE(qb.toString(), params)
      return result
    },
    onSuccess(_: any, variables: any) {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.getAllJobRoles, variables.userHandle, GetUserRoleModes.all],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.getAchievedRoles, variables.userHandle],
      })
    },
  })
}
