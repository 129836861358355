import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataItemApiResponse } from '@inphiz-shared/shared-models/src/Api/data-item-api-response'
import { QueryBuilder } from '@inphiz/core-utilities'
import type { FeedbackGroupItem } from '../Feedback'

const GroupDataDto = new DataItemApiResponse<FeedbackGroupItem>()
export type GroupItem = typeof GroupDataDto

interface GroupsParams {
    handle?: string
}

export function useGroupDetails({
    handle = ''
}: GroupsParams) {
    return useQuery<GroupItem>({
        queryKey: [QueryKey.groupDetails, handle],
        queryFn: async () => {
            const qb = new QueryBuilder(APIConst.groupDetail)
                .add('Handle', handle)
            const result = await APIFunctions.GET(qb.toString())
            return result
        },
        enabled: handle !== '',
    })
}
