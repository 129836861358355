import type { transportFunctionType } from 'react-native-logs'
import { consoleTransport, logger } from 'react-native-logs'

import { InteractionManager } from 'react-native'

import uuid from 'react-native-uuid'
import type { ILogger } from './ILogger'
import type { IBaseLogger } from './IBaseLogger'
import type { LogRecord } from './LogRecord'

const MAX_RECORDS = 70
const TRIM_RECORDS_TO = 50
const isProd = process.env.NODE_ENV === 'production'

export const RuntimeLogRecords: LogRecord[] = []

const runtimeTransport: transportFunctionType<{ myCustomOption: string }> = (props) => {
  const r: LogRecord = {
    level: props.level.text,
    msg: props.msg,
    id: uuid.v4().toString(),
  }

  RuntimeLogRecords.unshift(r)

  if (RuntimeLogRecords.length > MAX_RECORDS) {
    RuntimeLogRecords.length = TRIM_RECORDS_TO
  }

  // Do here whatever you want with the log message
  // You can use any options setted in config.transportOptions
  // Eg. a console log: console.log(props.level.text, props.msg)
  // console.log('runtimeTransport', props.level.text, props.msg)
}

function stringifyFunc(msg: any): string {
  let stringMsg = ''
  if (typeof msg === 'string') {
    stringMsg = `${msg} `
  }
  else if (typeof msg === 'function') {
    stringMsg = `[function ${msg.name}()] `
  }
  else if (msg && msg.stack && msg.message) {
    stringMsg = `${msg.message} `
  }
  else {
    try {
      stringMsg = `\n${JSON.stringify(msg, null, 3)}\n`
    }
    catch (error: any) {
      stringMsg += 'Undefined Message'
    }
  }
  return stringMsg
}

const LogRn = logger.createLogger({
  levels: {
    trace: 0,
    debug: 1,
    info: 2,
    warn: 3,
    error: 4,
    fatal: 5,
  },
  dateFormat: 'iso',
  transport: isProd ? [runtimeTransport] : [consoleTransport],
  transportOptions: {
    colors: {
      trace: 'grey',
      debug: 'cyan',
      info: 'blueBright',
      warn: 'yellowBright',
      error: 'redBright',
      fatal: 'magentaBright',
    },
    extensionColors: {
      root: 'magenta',
      home: 'grey',
      user: 'blue',
    },
    myCustomOption: 'some value',
  },
  fixedExtLvlLength: false,
  printLevel: true,
  printDate: true,
  async: false,
  asyncFunc: InteractionManager.runAfterInteractions,
  severity: 'trace',
  enabled: true,
  stringifyFunc,
})

// LogRn.debug('logger initialized', { someobjec: 'somevalue', objectValue: { a: 1, b: 2 } })

// const log: ILogFn = (...args: any[]): void => {
//   /// console.log('ilogfn')
//   console.log(
//     JSON.stringify(args, (key, value) => {
//       if (value instanceof Error) {
//         return serializeError(value)
//       }
//       return value
//     }),
//   )
// }

// function serializeError(error: any) {
//   return {
//     message: error.message,
//     name: error.name,
//     stack: error.stack,
//   }
// }

class RNLogger implements IBaseLogger {
  fatal = LogRn.fatal
  error = LogRn.error
  warn = LogRn.warn
  info = LogRn.info
  debug = LogRn.debug
  trace = LogRn.trace
}

const reactNativeLogger = new RNLogger() as IBaseLogger

export const Logger: ILogger = {
  Log: reactNativeLogger,
}
