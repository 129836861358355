import { useEffect, useState } from 'react'
import type { KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'
import FlatList from 'flatlist-react'
import { Checkbox } from 'rsuite'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { BorderButton, CustomAlert, CustomButton, CustomText, Spinner } from '../../atoms'
import { CloseIcon, SearchIcon, SortIcon } from '../../../icons'
import type { FeedbackGroupItem } from '../../../packages/api-web'
import { useFeedbackGroupList, usePostMember } from '../../../packages/api-web'
import { queryClient } from '../../../App'

interface GroupMembersProps {
  userHandle?: string
  addedGroups?: FeedbackGroupItem[]
  onCloseAlert?: () => void
}

export function AddGroupModal({ userHandle, addedGroups, onCloseAlert }: GroupMembersProps) {
  const { t } = useTranslation('common')
  const [searchTxt, setSearchTxt] = useState<string>('')
  const [sorting, setSorting] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState<FeedbackGroupItem[]>([])
  const [groupList, setGroupList] = useState<FeedbackGroupItem[]>([])
  const { isLoading, data, refetch: fetch } = useFeedbackGroupList(undefined, searchTxt, 'user')
  const { isPending, mutate } = usePostMember()

  useEffect(() => {
    const unmatchedGroups = data?.items?.filter(
      group => !addedGroups?.some(addedGroup => addedGroup.handle === group.handle),
    )
    setGroupList(unmatchedGroups ?? [])
  }, [data])

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter')
      fetch()
  }

  const renderItem = (item: FeedbackGroupItem, idx: string) => {
    return (
      <div
        className={`flex flex-1 h-12 pl-4 items-center ${Number.parseInt(idx) != groupList?.length - 1 ? 'border-b' : ''} border-stroke ${Number.parseInt(idx) % 2 == 0 ? 'bg-table-odd-bg' : ''}`}
      >
        <div className="text-left content-center w-[55%] h-12 border-r border-stroke overflow-hidden">
          <CustomText variant="mdPoppinsRegular">{item?.title ?? ''}</CustomText>
        </div>
        <div className="pl-4 flex w-[45%] items-center h-12 border-r border-stroke">
          <CustomText wrapText={false} variant="mdPoppinsRegular">
            {item?.subscriptionFlowType == 1 ? 'Customized' : 'Auto'}
          </CustomText>
        </div>
        <Checkbox
          checked={selectedItem?.includes(item)}
          onChange={() => {
            if (selectedItem?.includes(item)) {
              const temp = [...selectedItem]
              const index = selectedItem.indexOf(item)
              temp.splice(index, 1)
              setSelectedItem(temp)
            }
            else {
              setSelectedItem([...selectedItem, item])
            }
          }}
        />
      </div>
    )
  }

  if (isLoading)
    return <Spinner />

  return (
    <CustomAlert
      onClose={() => {
        onCloseAlert?.()
      }}
    >
      {isPending && <Spinner />}
      <CustomText variant="mdPoppinsSemiBold">Add groups</CustomText>
      <div className="border border-stroke rounded-md mt-4">
        <div className="flex items-center w-auto gap-4 h-10 px-4 border-b border-stroke">
          <SearchIcon />
          <input
            type="text"
            value={searchTxt}
            placeholder={t('searchPlaceholder')}
            className="outline-0 flex-1 min-w-[100px]"
            onChange={e => setSearchTxt(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          {searchTxt && (
            <button
              onClick={() => {
                setSearchTxt('')
                setTimeout(() => {
                  fetch()
                }, 1000)
              }}
            >
              <CloseIcon className="h-5" />
            </button>
          )}
        </div>
        <div
          className="flex items-center h-12 px-4 border-b border-stroke"
          onClick={() => {
            const temp = [...groupList]
            if (sorting == '' || sorting == 'asc') {
              setGroupList(temp.sort((a: any, b: any) => (a.displayName > b.displayName ? -1 : 1)))
              setSorting('des')
            }
            else {
              setGroupList(temp.sort((a: any, b: any) => (a.displayName > b.displayName ? 1 : -1)))
              setSorting('asc')
            }
          }}
        >
          <div className="flex items-center w-[17%]">
            <CustomText variant="mdPoppinsMedium">{t('title')}</CustomText>
            <SortIcon
              className={`h-5 w-5 ml-4 stroke-[#C9C9C9] ${sorting === 'asc' ? 'rotate-180' : ''}`}
            />
          </div>
          <div className="flex-1">
            <CustomText variant="mdPoppinsMedium">Type</CustomText>
          </div>
        </div>
        <div className="max-h-52 overflow-hidden overflow-y-auto scrollbar">
          <FlatList
            list={groupList}
            renderItem={renderItem}
            renderWhenEmpty={() => {
              return (
                <div className="h-10 flex flex-1 items-center justify-center">
                  <CustomText variant="mdPoppinsRegular">{t('noDataFound')}</CustomText>
                </div>
              )
            }}
          />
        </div>
        <div className="flex py-4 px-4 justify-end border-t border-stroke">
          <div className="flex gap-4 flex-wrap justify-end">
            <BorderButton
              children={t('cancel')}
              className="!px-2 flex-1"
              textColor="text-textPrimary"
              onClick={() => {
                onCloseAlert?.()
              }}
            />
            <CustomButton
              children={t('clear')}
              className="!px-4 flex-1"
              backgroundColor="bg-bthDisable"
              textColor="text-textPrimary"
              onClick={() => {
                setSelectedItem([])
              }}
            />
            <CustomButton
              children={t('add')}
              className="!px-6 flex-1"
              backgroundColor="bg-secondary"
              textColor="text-textWhite"
              onClick={() => {
                const data = selectedItem?.map((item) => {
                  const data = {
                    subscriberHandle: userHandle,
                    subscriberContentType: 'user',
                    isCustom: false,
                    isInactive: false,
                    groupHandle: item?.handle,
                    isDeleted: false,
                  }
                  return data
                })
                mutate(data, {
                  onSuccess: () => {
                    queryClient.invalidateQueries({
                      queryKey: [QueryKey.feedbackGroup, userHandle],
                    })
                    onCloseAlert?.()
                  },
                })
              }}
            />
          </div>
        </div>
      </div>
    </CustomAlert>
  )
}
