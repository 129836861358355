import { useTranslation } from 'react-i18next'
import {
  BorderButton,
  CustomButton,
  CustomDropDown,
  CustomInput,
  CustomText,
  TimeLine,
} from '../../component'
import CustomDatePicker from '../../component/atoms/CustomDatePicker'
import {
  BlueDraftIcon,
  DraftCheck2Icon,
  DraftCheckIcon,
  DraftIssuedIcon,
  DraftShareIcon,
} from '../../icons'

const step = [
  {
    icon: <BlueDraftIcon />,
    title: 'Utkast skapad',
    description: '2024-10-01 \nPernilla Mårtensson',
    active: true,
  },
  {
    icon: <DraftShareIcon />,
    title: 'Skickad för signering',
    description: '[YYYY-MM-DD]\n[Admin / skapare]',
    active: false,
  },
  {
    icon: <DraftCheckIcon />,
    title: 'Signering ansvarig',
    description: '[YYYY-MM-DD]\n[Ansvarig]',
    active: false,
  },
  {
    icon: <DraftCheck2Icon />,
    title: 'Kvittering anställd',
    description: '[YYYY-MM-DD]\n[Anställd]',
    active: false,
  },
  {
    icon: <DraftIssuedIcon />,
    title: 'Körtillstånd utfärdat',
    description: '',
    active: false,
  },
]

function CreateNewDrivingPermit() {
  const { t } = useTranslation('common')

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">{t('drivingLicense')}</CustomText>
      <div className="flex flex-1 flex-col mt-6 px-5 bg-white rounded-lg border border-stroke h-[100%] overflow-hidden">
        <CustomText variant="lgPoppinsSemiBold" className="my-3">
          {t('addNewDriving')}
        </CustomText>
        <div className="flex-1 overflow-hidden overflow-y-auto scrollbar">
          <div className="flex flex-1 border border-stroke justify-between py-3 px-3 rounded-lg">
            <div className="flex-1 px-5 py-2">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <div className="flex flex-1 flex-col">
                  <CustomDropDown
                    values={[]}
                    className="!mt-3 mb-5"
                    title={t('fieldDrivingLicense')}
                    options={[]}
                    labelField="titleIncludingParentsTitle"
                    valueField="handle"
                    placeholder={t('fieldDrivingLicensePlaceholder')}
                    onChange={(values) => {}}
                  />
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-5">
                    <CustomInput
                      title={t('fieldSection')}
                      value=""
                      placeholder={t('fieldSectionPlaceholder')}
                      onChange={(e) => {}}
                    />
                    <CustomInput
                      title={t('fieldBusiness')}
                      value=""
                      placeholder={t('fieldBusinessPlaceholder')}
                      onChange={(e) => {}}
                    />
                  </div>
                  <CustomDropDown
                    values={[]}
                    className="!mt-3 mb-5"
                    title={t('fieldSignDrivingPermit')}
                    options={[]}
                    labelField="titleIncludingParentsTitle"
                    valueField="handle"
                    placeholder={t('fieldSignDrivingPermitPlaceholder')}
                    onChange={(values) => {}}
                  />
                  <CustomInput
                    title={t('fieldTrainer')}
                    className="mb-5"
                    value=""
                    placeholder={t('fieldTrainerPlaceholder')}
                    onChange={(e) => {}}
                  />
                  <CustomInput
                    title={t('fieldTools')}
                    className="mb-5"
                    value=""
                    placeholder={t('fieldToolsPlaceholder')}
                    onChange={(e) => {}}
                  />
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-5">
                    <CustomDatePicker
                      title={t('fieldValidFrom')}
                      value={null}
                      placeholder="YYYY-MM-DD"
                      onChange={(value) => {}}
                    />
                    <CustomDatePicker
                      title={t('fieldValidTo')}
                      value={null}
                      placeholder="YYYY-MM-DD"
                      onChange={(value) => {}}
                    />
                  </div>
                  <CustomText variant="mdPoppinsSemiBold">
                    {t('fieldDrivingLicenseRefers')}
                  </CustomText>
                  <BorderButton
                    children={`+ ${t('addExperience')}`}
                    className="px-4 self-start mt-5"
                    onClick={() => {}}
                  />
                </div>
                <div className="flex flex-1 flex-col items-center justify-center">
                  <TimeLine step={step} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex gap-4 justify-center sm:justify-end py-4 rounded-md">
          <BorderButton
            children={t('back')}
            className="!px-14"
            textColor="text-textPrimary"
            onClick={() => {}}
          />
          <CustomButton
            children={t('save')}
            backgroundColor="bg-secondary"
            textColor="text-textWhite"
            onClick={() => {}}
          />
        </div>
      </div>
    </div>
  )
}

export default CreateNewDrivingPermit
