import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { appConfig } from '@inphiz/app-config/src/AppConfig'
import { useTranslation } from 'react-i18next'
import F_WelcomeScreens_CompanyLogoIcon from '../../../assets/F_WelcomeScreens/CompanyLogoIcon'
import {
  CampaignIcon,
  CloseIcon,
  ControlCenterIcon,
  DrivingLicenseIcon,
  EmployeesIcon,
  EquipmentIcon,
  ExperiencesIcon,
  GroupsIcon,
  MyDayIcon,
  NewsIcon,
  RightIcon,
  RoleManagementIcon,
  SettingIcon,
} from '../../../icons'

import { CustomText } from '../CustomText'
import { MenuItem } from './MenuItem'

interface CustomSlideMenuProps {
  sidebarToggle: boolean
  onClick: () => void
}

export function CustomSlideMenu({ sidebarToggle, onClick }: CustomSlideMenuProps) {
  const [selectedOption, setSelectedOption] = useState(1)
  const [expandSection, setExpandSection] = useState(0)
  const navigate = useNavigate()
  const { t } = useTranslation()

  // temporary solution
  const isDisabledForVerdis: boolean = appConfig.properties.BuildInfo.Flavor?.includes('urbaser')

  const menuItem = useMemo<MenuItem[]>(
    () => [
      new MenuItem({
        title: t('dashboard'),
        icon: <ControlCenterIcon />,
        id: 1,
        items: [
          new MenuItem({ title: t('home'), value: 'dashboard', id: 11 }),
          new MenuItem({ title: t('news'), value: 'news', id: 12 }),
          new MenuItem({ title: t('snack'), value: 'social', id: 13 }),
          new MenuItem({ title: t('my_apps'), id: 14, isVisible: !isDisabledForVerdis }),
          new MenuItem({
            title: t('ask_a_question'),
            value: 'chat',
            id: 15,
            // isVisible: !isDisabledForVerdis,
          }),
        ],
      }),
      new MenuItem({
        title: t('controlCenter'),
        icon: <ControlCenterIcon />,
        id: 2,
        isVisible: !isDisabledForVerdis,
      }),
      new MenuItem({
        title: t('feedback'),
        icon: <CampaignIcon className="h-[26px] text-white" />,
        value: 'feedback/campaign',
        id: 3,
        isVisible: !isDisabledForVerdis,
      }),
      new MenuItem({
        title: t('myDay'),
        icon: <MyDayIcon />,
        value: 'my_day',
        id: 4,
      }),
      new MenuItem({
        title: t('employees'),
        icon: <EmployeesIcon />,
        value: 'employee_list',
        id: 5,
        isVisible: !isDisabledForVerdis,
      }),
      new MenuItem({
        title: t('groups'),
        icon: <GroupsIcon />,
        value: 'group_list',
        id: 22,
      }),
      new MenuItem({
        title: t('experiences'),
        icon: <ExperiencesIcon />,
        value: 'experiences_list',
        id: 6,
        isVisible: !isDisabledForVerdis,
        items: [new MenuItem({ title: 'Type', value: 'experiences/type', id: 16 })],
      }),
      new MenuItem({
        title: t('news'),
        icon: <NewsIcon />,
        value: 'dashboard/news_list',
        id: 7,
      }),
      new MenuItem({
        title: t('roleManagement'),
        icon: <RoleManagementIcon />,
        value: 'role_management/position',
        id: 8,
        isVisible: !isDisabledForVerdis,
        items: [
          new MenuItem({ title: t('positions'), value: 'role_management/position', id: 17 }),
          new MenuItem({
            title: t('specializations'),
            value: 'role_management/specializationsList',
            id: 18,
          }),
          new MenuItem({ title: t('metaData'), value: 'role_management/metadata', id: 19 }),
        ],
      }),
      new MenuItem({
        title: t('equipment'),
        icon: <EquipmentIcon />,
        value: 'equipment_list',
        id: 9,
        isVisible: !isDisabledForVerdis,
        items: [new MenuItem({ title: t('categories'), value: 'equipment/category', id: 20 })],
      }),
      new MenuItem({
        title: t('drivingLicense'),
        icon: <DrivingLicenseIcon />,
        value: 'driving_license',
        id: 21,
      }),
      new MenuItem({
        title: t('settings'),
        icon: <SettingIcon />,
        id: 10,
      }),
    ],
    [t],
  )

  const findMenuItem = (
    menuItems: MenuItem[],
    path: string,
    parentId: number | null = null,
  ): { item: MenuItem | undefined, parentId: number | null } => {
    for (const item of menuItems) {
      if (item.value?.includes(path)) {
        return { item, parentId: parentId ?? item.id }
      }
      const found = item.items && findMenuItem(item.items, path, item.id)
      if (found?.item) {
        return found
      }
    }
    return { item: undefined, parentId: null }
  }

  useEffect(() => {
    const path = window.location.pathname.replace('/', '')
    const { item: foundOption, parentId } = findMenuItem(menuItem, path)
    if (foundOption) {
      setSelectedOption(parentId ?? foundOption.id)
    }
  }, [window.location, menuItem])

  const handleMenuClick = (id: number, value?: string, hasSubMenu = false, isMobile = false) => {
    if (value) {
      navigate(`/${value}`)
      if (isMobile)
        onClick()
    }
    if (hasSubMenu)
      setExpandSection(expandSection === id ? 0 : id)
    setSelectedOption(id)
  }

  const renderMenuItems = (isMobile = false) =>
    menuItem
      .filter(x => x.isVisible)
      .map(i => (
        <div key={i.id}>
          <li
            className={`pr-2 ${isMobile ? 'py-4' : 'pl-4 py-3'} ${
              i.id === selectedOption && 'border-l-secondary border-l-4 bg-white/5'
            } cursor-pointer`}
            onClick={() =>
              handleMenuClick(i.id, i.value, i.items.filter(x => x.isVisible).length > 0, isMobile)}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center px-4">
                {i.icon}
                <CustomText
                  className={`${isMobile ? 'ml-[30px]' : 'ml-2'}`}
                  textColor="text-textWhite"
                  variant="smPoppinsRegular"
                >
                  {i.title}
                </CustomText>
              </div>
              {i.items.filter(x => x.isVisible).length > 0 && (
                <RightIcon className={`${expandSection === i.id && 'rotate-90'}`} />
              )}
            </div>
          </li>
          {expandSection === i.id && (
            <ul>
              {i.items
                .filter(x => x.isVisible)
                .map(j => (
                  <li
                    key={j.id}
                    className="cursor-pointer"
                    onClick={() => handleMenuClick(j.id, j.value, false, isMobile)}
                  >
                    <div
                      className={`flex items-center px-4 py-4 ${
                        j.id === selectedOption && 'border-l-secondary border-l-4 bg-white/5'
                      }`}
                    >
                      <CustomText
                        className="ml-[30px]"
                        textColor="text-textWhite"
                        variant="smPoppinsRegular"
                      >
                        {j.title}
                      </CustomText>
                    </div>
                  </li>
                ))}
            </ul>
          )}
        </div>
      ))

  return (
    <div className="h-full">
      {/* Desktop Menu */}
      <div
        className={`${
          sidebarToggle ? 'translate-x-0' : '-translate-x-full'
        } ease-in-out duration-500 w-64 bg-primary fixed h-screen hidden xl:block top-[62.5px]`}
      >
        <ul className="text-white h-[80%] overflow-y-auto scrollbar">{renderMenuItems()}</ul>
        <div className="flex flex-col items-start absolute bottom-20 space-y-1">
          <div className="mx-4">
            <F_WelcomeScreens_CompanyLogoIcon width={80} height={30} />
          </div>
          {/* <img
            src={inphiz_white_logo}
            className="mx-4"
            style={{ height: 30, width: 80 }}
            alt="app_logo"
          /> */}
          <p className="mx-4 whitespace-nowrap text-white opacity-30 text-xs">
            {appConfig.properties.BuildInfo.BuildVersionInfo}
          </p>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        aria-hidden="true"
        className={`${!sidebarToggle ? 'translate-x-0' : '-translate-x-full'} ease-in-out duration-200 fixed top-0 right-0 bottom-0 left-0 bg-black/50 z-50 xl:hidden`}
        onClick={onClick}
      >
        <button className="absolute z-40 top-4 right-4 p-1 bg-white rounded-full" onClick={onClick}>
          <CloseIcon />
        </button>
        <div className="w-4/5 bg-primary h-full fixed py-2" onClick={e => e.stopPropagation()}>
          <div className="px-3">
            <F_WelcomeScreens_CompanyLogoIcon width={100} height={50} />
          </div>
          {/* <img
            src={inphiz_logo}
            className="px-3"
            style={{ height: 50, width: 100 }}
            alt="app_logo"
          /> */}
          <ul className="mt-2 text-white">{renderMenuItems(true)}</ul>
        </div>
      </div>
    </div>
  )
}
