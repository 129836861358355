import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import type { SocialSecurityNumberDto } from '@inphiz-shared/shared-models/src/F_SocialSecurityNumber/social-security-number-dto'

export function usePostSSN() {
  return useMutation({
    mutationFn: async (params: SocialSecurityNumberDto) => {
      const data = {
        socialSecurityNumber: params,
      }
      const result = await APIFunctions.POST(APIConst.usersDirectory.sensitiveData, data)
      return result
    },
  })
}
