import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { Statuses } from '@inphiz-shared/shared-models/src/F_Certificates/UserCertificateConstants/statuses'
import { CustomText, Spinner } from '../../atoms'
import { CertificateIcon, MyProfile, Policy } from '../../../icons'
import {
  useCertificateItems,
  useCertificateList,
  useEmployeeProgress,
} from '../../../packages/api-web/Employee'

interface Params {
  handle: string
}

export function EmployeeStatus({ handle }: Params) {
  const { t } = useTranslation('common')

  const { isLoading: isEmployeeProgressLoad, data: employeeProgress } = useEmployeeProgress({
    handle,
  })
  const { isLoading: isCertificateListLoad, data: certificateList } = useCertificateList()
  const { isLoading: isCertificateItemsLoad, data: certificateItems } = useCertificateItems({
    handle,
  })

  const progressData = useMemo(() => {
    const result = employeeProgress?.progress?.progressSummaries
      ?.filter(i => i?.name == 'ProgressSummaryProfile' || i?.name == 'ProgressSummaryPolicy')
      ?.sort((a, b) => {
        if (a.name === 'ProgressSummaryProfile')
          return -1
        if (b.name === 'ProgressSummaryProfile')
          return 1
        return 0
      })
    return result
  }, [employeeProgress])

  const certificateData = useMemo(() => {
    const items = certificateItems?.items?.filter(i => i?.status?.name !== Statuses.archived)
    const data = items?.map((i) => {
      return {
        ...i,
        certificateTitle: certificateList?.items?.find(j => j?.handle === i?.certificateHandle)
          ?.certificateType?.title,
      }
    })
    let filterArray: { totalCount: number, count: number, title: string }[] = []
    if (data) {
      filterArray = Object.values(
        data.reduce(
          (acc, certificate) => {
            const { status, certificateTitle } = certificate
            if (!acc[certificateTitle ?? '']) {
              acc[certificateTitle ?? ''] = {
                title: certificateTitle ?? '',
                totalCount: 0,
                count: 0,
              }
            }
            acc[certificateTitle ?? ''].totalCount++
            if (status?.name === Statuses.approved) {
              acc[certificateTitle ?? ''].count++
            }
            return acc
          },
          {} as Record<string, { totalCount: number, count: number, title: string }>,
        ),
      )
    }
    return filterArray
  }, [certificateList, certificateItems])

  const iconAndTitle = (key: string) => {
    switch (key) {
      case 'ProgressSummaryProfile':
        return { icon: <MyProfile />, title: t('myProfile') }
      case 'ProgressSummaryPolicy':
        return { icon: <Policy />, title: t('policies') }
      default:
        break
    }
  }

  return (
    <div className="max-lg:mt-3 rounded-lg border border-stroke">
      {isEmployeeProgressLoad || isCertificateListLoad || (isCertificateItemsLoad && <Spinner />)}
      <CustomText variant="mdPoppinsSemiBold" className="flex-1 border-b px-5 py-3 border-stroke">
        {t('status')}
      </CustomText>
      {progressData?.map((i) => {
        return (
          <div className="px-5 py-3 items-center grid grid-cols-7">
            {iconAndTitle(i?.name ?? '')?.icon ?? ''}
            <CustomText variant="mdPoppinsRegular" className="col-span-4">
              {iconAndTitle(i?.name ?? '')?.title ?? ''}
            </CustomText>
            <CustomText variant="mdPoppinsRegular" className="text-center">
              {`${i?.count}/${i?.totalCount}`}
            </CustomText>
            <div
              className={`h-2 w-2 rounded-full place-self-center ${(i?.count ?? 0) < (i?.totalCount ?? 0) ? (i?.name == 'ProgressSummaryProfile' ? 'bg-yellow' : 'bg-textDanger') : 'bg-green'}`}
            />
          </div>
        )
      })}
      {certificateData?.map((i) => {
        return (
          <div className="px-5 py-3 items-center grid grid-cols-7">
            <CertificateIcon />
            <CustomText variant="mdPoppinsRegular" className="col-span-4">
              {i?.title}
            </CustomText>
            <CustomText variant="mdPoppinsRegular" className="text-center">
              {`${i?.count}/${i?.totalCount}`}
            </CustomText>
            <div
              className={`h-2 w-2 rounded-full place-self-center ${(i?.count ?? 0) < (i?.totalCount ?? 0) ? 'bg-textDanger' : 'bg-green'}`}
            />
          </div>
        )
      })}
    </div>
  )
}
