export function GroupsIcon({ className = '' }) {
  return (
    <svg width={27} height={17} viewBox="0 0 27 17" fill="none" className={className}>
      <path
        d="M3.91667 8.5C4.49353 8.5 5.05744 8.32893 5.53708 8.0084C6.01673 7.68788 6.39055 7.2324 6.6113 6.69938C6.83206 6.16637 6.88982 5.57964 6.77728 5.0138C6.66474 4.44795 6.38695 3.92824 5.97904 3.52029C5.57114 3.11234 5.05146 2.83468 4.48568 2.72213C3.91991 2.60957 3.33345 2.66711 2.8005 2.88789C2.26755 3.10867 1.81202 3.48281 1.49154 3.96251C1.17105 4.44221 1 5.00605 1 5.58298C1 6.35662 1.30731 7.09863 1.85429 7.64567C2.40127 8.19272 3.14312 8.5 3.91667 8.5Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.51556 12.367C8.22091 11.6784 7.77537 11.0647 7.21185 10.5715C6.64834 10.0782 5.98124 9.71808 5.25981 9.51722C4.53839 9.31637 3.78106 9.28006 3.04376 9.41115C2.30646 9.54223 1.60801 9.8372 1 10.2744"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.0834 8.5C23.6602 8.5 24.2241 8.32893 24.7038 8.0084C25.1834 7.68788 25.5572 7.2324 25.778 6.69938C25.9987 6.16637 26.0565 5.57964 25.944 5.0138C25.8314 4.44795 25.5536 3.92824 25.1457 3.52029C24.7378 3.11234 24.2181 2.83468 23.6524 2.72213C23.0866 2.60957 22.5001 2.66711 21.9672 2.88789C21.4342 3.10867 20.9787 3.48281 20.6582 3.96251C20.3377 4.44221 20.1667 5.00605 20.1667 5.58298C20.1667 6.35662 20.474 7.09863 21.021 7.64567C21.568 8.19272 22.3098 8.5 23.0834 8.5Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4844 12.367C18.779 11.6784 19.2246 11.0647 19.7881 10.5715C20.3516 10.0782 21.0187 9.71808 21.7401 9.51722C22.4616 9.31637 23.2189 9.28006 23.9562 9.41115C24.6935 9.54223 25.3919 9.8372 25.9999 10.2744"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 8.50084C14.2417 8.50084 14.9667 8.28082 15.5834 7.86871C16.2001 7.45661 16.6807 6.87087 16.9646 6.18557C17.2484 5.50026 17.3226 4.74621 17.1779 4.0187C17.0333 3.29118 16.6761 2.62295 16.1516 2.09844C15.6272 1.57393 14.959 1.21682 14.2316 1.07211C13.5041 0.9274 12.7502 1.00149 12.0649 1.28535C11.3797 1.56922 10.794 2.05005 10.382 2.66681C9.96993 3.28357 9.75 4.00863 9.75 4.75039C9.75 5.74507 10.1451 6.699 10.8484 7.40235C11.5516 8.10569 12.5054 8.50084 13.5 8.50084Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1666 15.999C19.9496 14.3846 19.1542 12.9036 17.9282 11.8313C16.7021 10.759 15.1287 10.168 13.5 10.168C11.8712 10.168 10.2978 10.759 9.07178 11.8313C7.84574 12.9036 7.05037 14.3846 6.83331 15.999"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
