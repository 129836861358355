import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst, QueryKey } from '@inphiz/api'
import type { UpdateUserApiRequest } from '@inphiz-shared/shared-models/src/F_Users/update-user-api-request'
import { queryClient } from '../../../App'

export function useUpdateEmployee() {
  return useMutation({
    mutationFn: async (params: UpdateUserApiRequest) => {
      const result = await APIFunctions.POST(APIConst.usersDirectory.updateUserProfile, params)
      return result
    },
    onSuccess: (_: any, variables: any) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.getEmployeeDetails, variables.handle] })
    },
  })
}
