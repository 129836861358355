import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckedIcon, DeleteIcon, PenIcon } from '../../../icons'
import { CustomDropDown, CustomInput, CustomText } from '../../atoms'
import type { SurveyParam } from '../../../packages/api-web'

interface SurveyTableProps {
  tableDataList: SurveyParam[]
  isEdited: boolean
  setIsEdited: React.Dispatch<React.SetStateAction<boolean>>
  onDeletePress?: (index: number) => void
  tableEdit: (index: number, field: string, value: any) => void
}

const QuestionList = [
  { title: 'Slider', id: 1 },
  { title: 'Happy/sad', id: 3 },
  { title: 'Yes/No', id: 4 },
  { title: 'Stars', id: 5 },
  { title: 'Rating', id: 6 },
  { title: 'Happiness rating', id: 7 },
]

export function SurveyTable({
  tableDataList,
  isEdited,
  setIsEdited,
  onDeletePress,
  tableEdit,
}: SurveyTableProps) {
  const { t } = useTranslation('common')
  const [isError, setIsError] = useState(false)

  return (
    <table className="w-full text-left table-auto mt-4 border border-stroke cm-table">
      <thead>
        <tr>
          {[t('surveyTableTitle'), t('surveyTableContent'), t('surveyTableResponse'), ''].map(
            (header, idx) => (
              <th key={idx} className="p-2 bg-primary border-r border-white">
                {idx !== 3 && (
                  <CustomText
                    textColor="text-textWhite"
                    variant={idx === 0 ? 'mdPoppinsSemiBold' : 'smPoppinsSemiBold'}
                  >
                    {header}
                  </CustomText>
                )}
              </th>
            ),
          )}
        </tr>
      </thead>
      <tbody>
        {tableDataList.length > 0 ? (
          tableDataList.map((item, index) => (
            <tr key={index} className="odd:bg-table-odd-bg border-t border-stroke">
              <td className="p-2 border-r border-stroke">
                <CustomInput
                  disabled={item.selected}
                  value={item.title}
                  className={`!mt-0 ${item.selected ? '!outline-none !bg-transparent !p-0' : '!bg-white'} ${!item.title && isError ? 'outline-textDanger' : ''}`}
                  onChange={e => tableEdit(index, 'title', e.target.value)}
                />
              </td>
              <td className="p-2 border-r border-r-stroke">
                <CustomInput
                  disabled={item.selected}
                  value={item.content}
                  className={`!mt-0 ${item.selected ? '!outline-none !bg-transparent !p-0' : '!bg-white'} ${!item.content && isError ? 'outline-textDanger' : ''}`}
                  onChange={e => tableEdit(index, 'content', e.target.value)}
                />
              </td>
              <td className="p-2 border-r border-r-stroke">
                {item.selected ? (
                  <CustomText variant="smPoppinsRegular">
                    {QuestionList.find(i => i.id == item?.inputTypeConfiguration?.inputType)
                      ?.title ?? ''}
                  </CustomText>
                ) : (
                  <CustomDropDown
                    className={`${item.selected ? '!bg-transparent' : '!bg-white'}`}
                    values={QuestionList.filter(
                      i => i.id == item?.inputTypeConfiguration?.inputType,
                    )}
                    options={QuestionList}
                    labelField="title"
                    valueField="id"
                    onChange={(value) => {
                      tableEdit(index, 'inputTypeConfiguration', { inputType: value[0]?.id })
                    }}
                  />
                )}
              </td>
              <td className="p-2">
                <div
                  className={`flex justify-end gap-1 ${isEdited && item.selected ? 'opacity-35' : ''}`}
                >
                  {item.selected ? (
                    <button
                      disabled={isEdited && item.selected}
                      onClick={() => {
                        setIsEdited(true)
                        setIsError(false)
                        tableEdit(index, 'selected', !item.selected)
                      }}
                      className="h-8 w-8 flex justify-center items-center bg-bgColor rounded-md"
                    >
                      <PenIcon className="h-5 w-5 fill-black" />
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        if (item.title && item.content) {
                          setIsEdited(false)
                          setIsError(false)
                          tableEdit(index, 'selected', !item.selected)
                        }
                        else {
                          setIsError(true)
                        }
                      }}
                    >
                      <CheckedIcon className="h-8 w-8" />
                    </button>
                  )}
                  <button
                    onClick={() => onDeletePress?.(index)}
                    disabled={isEdited && item.selected}
                    className="h-8 w-8 flex justify-center items-center bg-red rounded-md"
                  >
                    <DeleteIcon className="fill-textDanger" />
                  </button>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td className="p-2 bg-white" colSpan={4}>
              <CustomText variant="mdPoppinsRegular">No records to display.</CustomText>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
