export function DraftShareIcon({ className = '' }) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none" className={className}>
      <path
        d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
        fill="white"
      />
      <path
        d="M24 47C36.7025 47 47 36.7025 47 24C47 11.2975 36.7025 1 24 1C11.2975 1 1 11.2975 1 24C1 36.7025 11.2975 47 24 47Z"
        stroke="#999999"
        strokeWidth={2}
      />
      <path
        d="M24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42Z"
        fill="#999999"
      />
      <path
        d="M13.8835 23.6836C13.6341 23.6132 13.4137 23.4648 13.2548 23.2601C13.0958 23.0554 13.0067 22.8052 13.0004 22.5462C12.9941 22.2871 13.071 22.0329 13.2198 21.8207C13.3686 21.6085 13.5815 21.4496 13.8273 21.3672L30.1976 16.0302C30.3049 15.9952 30.4197 15.9905 30.5294 16.0167C30.6391 16.0428 30.7395 16.0987 30.8194 16.1782C30.8994 16.2577 30.9559 16.3578 30.9826 16.4673C31.0093 16.5768 31.0052 16.6916 30.9708 16.799L25.6358 33.1715C25.5538 33.4176 25.3949 33.6308 25.1825 33.7799C24.9702 33.929 24.7156 34.006 24.4561 33.9996C24.1967 33.9932 23.9462 33.9038 23.7414 33.7444C23.5367 33.5851 23.3885 33.3642 23.3187 33.1144L21.4868 25.5066L13.8835 23.6836Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.8176 16.1777L21.4844 25.5075"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
