import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst, QueryKey } from '@inphiz/api'
import type { CertificateItemDto } from '@inphiz-shared/shared-models/src/F_Certificates/certificate-item-dto'
import { queryClient } from '../../../App'

interface Params {
  handle?: string
}

export function usePostRenewCetificate({ handle }: Params) {
  return useMutation({
    mutationFn: async (params: CertificateItemDto) => {
      const data = {
        item: params,
      }
      const result = await APIFunctions.POST(
        `${APIConst.employeesPerformance.renewCertificate}?handle=${handle}`,
        data,
      )
      return result
    },
    onSuccess(_: any, variables: any) {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.getCertificateItemsType, variables.certificateTypeHandle],
      })
    },
  })
}
