import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { JobTitleDto } from '@inphiz-shared/shared-models/src/F_Selfleadership/job-title-dto'
import { QueryBuilder } from '@inphiz/core-utilities'

const JobTitleItemDto = new JobTitleDto()
export type AvailableJobTitleItem = typeof JobTitleItemDto

const JobTitleListDto = new DataCollectionApiResponse<AvailableJobTitleItem>()
export type AvailableJobTitleData = typeof JobTitleListDto

interface params {
  handle: string
}

export function useAvailableJobTitle({ handle }: params) {
  return useQuery<AvailableJobTitleData>({
    queryKey: [QueryKey.getAvailableJobTitle],
    queryFn: async () => {
      const qb = new QueryBuilder(`${APIConst.availableJobTitle}/${handle}` + `/available`)
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
