import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst, QueryKey } from '@inphiz/api'
import type { PromoteUserApiRequest } from '@inphiz-shared/shared-models/src/F_Selfleadership/promote-user-api-request'
import { GetUserRoleModes } from '@inphiz-shared/shared-models/src/F_Selfleadership/Constants/get-user-role-modes'
import { queryClient } from '../../../App'

export function usePostJobRoleOffer() {
  return useMutation({
    mutationFn: async (params: PromoteUserApiRequest) => {
      const result = await APIFunctions.POST(
        `${APIConst.employeesPerformance.jobRolesPromote}`,
        params,
      )
      return result
    },
    onSuccess(_: any, variables: any) {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.getAllJobRoles, variables.userHandle, GetUserRoleModes.all],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.getAchievedRoles, variables.userHandle],
      })
    },
  })
}
