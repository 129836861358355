function cleanPercentage(percentage: number) {
  const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0 // we can set non-numbers to 0 here
  const isTooHigh = percentage > 100
  return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage
}

function Circle({
  colour,
  percentage,
  radius,
  cx,
  cy,
}: {
  colour: string
  percentage: number
  radius: number
  cx: number
  cy: number
}) {
  const circ = 2 * Math.PI * radius // Circumference
  const strokePct = ((100 - percentage) * circ) / 100 // Stroke offset
  return (
    <circle
      r={radius}
      cx={cx}
      cy={cy}
      fill="transparent"
      strokeWidth="10px"
      strokeDasharray={circ}
      strokeDashoffset={percentage ? strokePct : 0}
      className={strokePct !== circ ? colour : ''}
      strokeLinecap="round"
    />
  )
}

interface Params {
  percentage: number
  colour: string
  size?: number // Add size prop for customization
  strokeWidth?: number
  className?: string
}

export function CustomCircleBar({
  percentage,
  colour,
  size = 200,
  strokeWidth = 10,
  className,
}: Params) {
  const radius = (size - strokeWidth) / 2
  const pct = cleanPercentage(percentage ?? 0)
  return (
    <svg width={size} height={size} className={className}>
      <g transform={`rotate(-90 ${size / 2} ${size / 2})`}>
        {/* Background Circle */}
        <Circle
          colour="stroke-bgColor"
          percentage={100}
          radius={radius}
          cx={size / 2}
          cy={size / 2}
        />
        {/* Foreground Progress Circle */}
        <Circle colour={colour} percentage={pct} radius={radius} cx={size / 2} cy={size / 2} />
      </g>
    </svg>
  )
}
