import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { QueryBuilder } from '@inphiz/core-utilities'
import { GetUserProgressApiResponse } from '@inphiz-shared/shared-models/src/F_Progress/get-user-progress-api-response'

const UserProgressDTO = new GetUserProgressApiResponse()
export type UserProgress = typeof UserProgressDTO

interface Params {
  handle?: string
}

export function useEmployeeProgress({ handle }: Params) {
  return useQuery<UserProgress>({
    queryKey: [QueryKey.getEmployeeProgress, handle],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.userProgress).add('handle', handle)
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
