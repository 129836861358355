import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import type { NewsDetailsParam } from '../News'

interface DashboardNewDetailsParam {
  handle: string
}

export function useDashboardNewDetails({ handle }: DashboardNewDetailsParam) {
  return useQuery<NewsDetailsParam>({
    queryKey: [QueryKey.dashboardNewDetails, handle],
    queryFn: async () => APIFunctions.GET(`${APIConst.newsDetails}?Handle=${handle}`),
    enabled: !!handle,
  })
}
