import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { RecommendedActionCardDto } from '@inphiz-shared/shared-models/src/F_RecomededActions/recommended-action-card-dto'
import { QueryBuilder } from '@inphiz/core-utilities'

const RecommendedActionCardItemDto = new RecommendedActionCardDto()
export type RecommendedActionCardItem = typeof RecommendedActionCardItemDto

const RecommendedActionCardListDto = new DataCollectionApiResponse<RecommendedActionCardItem>()
type RecommendedActionCardList = typeof RecommendedActionCardListDto

interface Params {
  handle?: string
}
export function useRecommendedActionsEntity({ handle }: Params) {
  return useQuery<RecommendedActionCardList>({
    queryKey: [QueryKey.getRecommendedActionsEntity, handle],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.employeesPerformance.getRecommendedActionsEntity).add(
        'EntityHandle',
        handle,
      )
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
