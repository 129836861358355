export function CertificateIcon({ className = '' }) {
  return (
    <svg width={25} height={25} viewBox="0 0 25 25" className={className}>
      <defs>
        <clipPath id="clip-certificate">
          <rect width={25} height={25} />
        </clipPath>
      </defs>
      <g id="certificate" clipPath="url(#clip-certificate)">
        <g id="certified-certificate" transform="translate(-186.5 -1355.5)">
          <path
            id="Shape_484"
            data-name="Shape 484"
            d="M193.75,1374.75V1377a2.25,2.25,0,0,1-2.25,2.25H202a2.25,2.25,0,0,0,2.25-2.25v-17.09c0-.566.438-3.16,3-3.16a3,3,0,0,1,3,3v3h-6"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
          />
          <path
            id="Shape_485"
            data-name="Shape 485"
            d="M207.25,1356.75h-9"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
          />
          <path
            id="Shape_486"
            data-name="Shape 486"
            d="M189.737,1364.98l-.8,5.879a.751.751,0,0,0,1.269.666l2.049-2.05,2.05,2.05a.751.751,0,0,0,1.269-.666l-.8-5.879"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
          />
          <path
            id="Oval_50"
            data-name="Oval 50"
            d="M192.25,1365.75a4.5,4.5,0,1,0-4.5-4.5A4.5,4.5,0,0,0,192.25,1365.75Z"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
          />
          <path
            id="Shape_487"
            data-name="Shape 487"
            d="M192.25,1360.88a.375.375,0,1,0,.375.375.375.375,0,0,0-.375-.375"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
          />
        </g>
      </g>
    </svg>
  )
}
