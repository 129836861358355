import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { QueryBuilder } from '@inphiz/core-utilities'

const ReasonsDto = new DataCollectionApiResponse()
export type ArchivingReasonsData = typeof ReasonsDto

export function useArchivingReasons() {
  return useQuery<ArchivingReasonsData>({
    queryKey: [QueryKey.getArchivingReasons],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.employeesPerformance.archivingReasons)
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
