import type { SelectProps, SelectRenderer } from 'react-dropdown-select'
import Select from 'react-dropdown-select'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { CustomText } from '../CustomText'

export interface CustomDropDownProps extends SelectProps<any> {
  title?: string
  labelField?: string
  valueField?: string
  className?: string
  searchFn?: ({ props, state, methods }: SelectRenderer<any>) => any
}

export function CustomDropDown({
  title,
  labelField,
  valueField,
  className,
  searchFn,
  ...props
}: CustomDropDownProps) {
  const { t } = useTranslation('common')
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="flex flex-col cm-dropdown-icon">
      {title && <CustomText variant="mdPoppinsSemiBold">{title}</CustomText>}
      <Select
        className={`!rounded-lg !border-0 !outline !outline-stroke !pl-3 !pr-2 ${isOpen ? '!outline-2' : '!outline-1'} ${className}`}
        color="lightgray"
        searchable={false}
        placeholder={t('select')}
        labelField={labelField}
        valueField={valueField}
        dropdownPosition="auto"
        onDropdownOpen={() => setIsOpen(true)}
        onDropdownClose={() => setIsOpen(false)}
        searchFn={searchFn}
        {...props}
      />
    </div>
  )
}
