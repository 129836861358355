import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { PolicyDto } from '@inphiz-shared/shared-models/src/F_Policy/policy-dto'
import { QueryBuilder } from '@inphiz/core-utilities'

const PolicyItemDto = new PolicyDto()
export type PolicyItem = typeof PolicyItemDto

const PolicyListDto = new DataCollectionApiResponse<PolicyItem>()
export type PolicyList = typeof PolicyListDto

export function useGetAllPolicy() {
  return useQuery<PolicyList>({
    queryKey: [QueryKey.getAllPolicy],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.getAllPolicy).add('skip', 0).add('take', 2147483647)
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
