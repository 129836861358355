import * as React from 'react'

export function DrivingLicenseIcon() {
  return (
    <svg width={27} height={28} viewBox="0 0 27 28" fill="none">
      <path
        d="M22.4809 12.7647H24.827C25.1381 12.7647 25.4365 12.6694 25.6564 12.4996C25.8764 12.3299 26 12.0997 26 11.8597V1.90498C26 1.66496 25.8764 1.43478 25.6564 1.26507C25.4365 1.09535 25.1381 1 24.827 1H16.5149C16.204 1.00005 15.9059 1.09531 15.686 1.26485L13.4401 2.99759C13.2203 3.16724 13.0968 3.39726 13.0968 3.63711V6.42986"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.6562 4.33789H16.6154"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.6523 6.82617H16.6034"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.6562 9.25781L20.5263 9.23896"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7934 26.9994C17.1257 26.9994 17.4505 26.9031 17.7268 26.7227C18.0031 26.5424 18.2184 26.2861 18.3456 25.9862C18.4728 25.6863 18.506 25.3562 18.4412 25.0379C18.3764 24.7195 18.2164 24.4271 17.9814 24.1976C17.7464 23.968 17.4471 23.8117 17.1212 23.7483C16.7952 23.685 16.4574 23.7175 16.1504 23.8417C15.8434 23.966 15.5811 24.1763 15.3964 24.4462C15.2118 24.7161 15.1133 25.0335 15.1133 25.3581C15.1133 25.7934 15.2903 26.2108 15.6054 26.5186C15.9204 26.8264 16.3478 26.9994 16.7934 26.9994Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.68011 26.9994C3.0124 26.9994 3.33723 26.9031 3.61352 26.7227C3.88981 26.5424 4.10516 26.2861 4.23232 25.9862C4.35948 25.6863 4.39275 25.3562 4.32792 25.0379C4.26309 24.7195 4.1031 24.4271 3.86813 24.1976C3.63317 23.968 3.33378 23.8117 3.00787 23.7483C2.68196 23.685 2.34416 23.7175 2.03716 23.8417C1.73016 23.966 1.46778 24.1763 1.28316 24.4462C1.09855 24.7161 1 25.0335 1 25.3581C1 25.7934 1.177 26.2108 1.49208 26.5186C1.80716 26.8264 2.23452 26.9994 2.68011 26.9994Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.03948 26.9994C6.37178 26.9994 6.6966 26.9031 6.97289 26.7227C7.24919 26.5424 7.46453 26.2861 7.5917 25.9862C7.71886 25.6863 7.75212 25.3562 7.68729 25.0379C7.62247 24.7195 7.46247 24.4271 7.22751 24.1976C6.99254 23.968 6.69315 23.8117 6.36725 23.7483C6.04134 23.685 5.70353 23.7175 5.39654 23.8417C5.08954 23.966 4.82715 24.1763 4.64254 24.4462C4.45793 24.7161 4.35938 25.0335 4.35938 25.3581C4.35938 25.7934 4.53638 26.2108 4.85146 26.5186C5.16654 26.8264 5.59389 26.9994 6.03948 26.9994Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.082 25.0303V22.4043H19.8186C20.1751 22.4043 20.5169 22.5426 20.769 22.7889C21.0211 23.0351 21.1627 23.3691 21.1627 23.7173C21.1627 24.0656 21.0211 24.3995 20.769 24.6458C20.5169 24.892 20.1751 25.0303 19.8186 25.0303H18.4414"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4906 22.5791V17.152C20.4906 16.1073 20.0658 15.1053 19.3096 14.3666C18.5534 13.6279 17.5278 13.2129 16.4584 13.2129H11.082V22.4041"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.082 13.2144V10.2024C11.0819 10.0054 11.1272 9.81077 11.2145 9.63317C11.3019 9.45557 11.429 9.29945 11.5865 9.17637C11.744 9.05329 11.9278 8.9664 12.1243 8.92216C12.3209 8.87792 12.5251 8.87753 12.7218 8.92088C14.1483 9.23338 16.0498 9.78914 16.4584 10.584C16.8419 11.4128 17.0695 12.3024 17.1304 13.21V13.2651"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.39247 21.0918H1"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.39247 22.4039V9.93021C8.39247 9.58198 8.25087 9.248 7.99881 9.00176C7.74674 8.75552 7.40486 8.61719 7.04839 8.61719H1"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.149 25.0293H7.6875"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
