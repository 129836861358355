import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { queryClient } from '../../../App'

function onSuccess(_:any,variables:any) {
    queryClient.invalidateQueries({ queryKey: [QueryKey.groupList] })
    queryClient.invalidateQueries({ queryKey: [QueryKey.groupDetails, variables.handle] })
}

export function usePutGroup() {
    return useMutation({
        mutationFn: async (data: any) => {
            const params = {
                item: data,
            }
            const result = await APIFunctions.PUT(
                APIConst.newCategory,
                params,
            )
            return result
        },
        onSuccess
    })
}