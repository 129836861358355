import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { CertificateTypeDto } from '@inphiz-shared/shared-models/src/F_Certificates/certificate-type-dto'
import { QueryBuilder } from '@inphiz/core-utilities'

const CertificateType = new CertificateTypeDto()
export type CertificateTypeItem = typeof CertificateType

const CertificateTypeListDto = new DataCollectionApiResponse<CertificateTypeItem>()
export type CertificateTypeData = typeof CertificateTypeListDto

export function useCertificateTypes() {
  return useQuery<CertificateTypeData>({
    queryKey: [QueryKey.getCertificateTypes],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.certificateType).add('take', 2147483647)
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
