import * as React from 'react'

export function DownloadIcon({ className = '' }) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16 7.75V19.75"
        stroke="#091D3E"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 15.25L16 19.75L20.5 15.25"
        stroke="#091D3E"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.25 19.75V21.25C27.25 22.0456 26.9339 22.8087 26.3713 23.3713C25.8087 23.9339 25.0456 24.25 24.25 24.25H7.75C6.95435 24.25 6.19127 23.9339 5.62866 23.3713C5.06605 22.8087 4.75 22.0456 4.75 21.25V19.75"
        stroke="#091D3E"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
