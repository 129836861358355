export function DraftCheckIcon({ className = '' }) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none" className={className}>
      <path
        d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
        fill="white"
      />
      <path
        d="M24 47C36.7025 47 47 36.7025 47 24C47 11.2975 36.7025 1 24 1C11.2975 1 1 11.2975 1 24C1 36.7025 11.2975 47 24 47Z"
        stroke="#999999"
        strokeWidth={2}
      />
      <path
        d="M24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42Z"
        fill="#999999"
      />
      <path
        d="M33 15L20.9264 32.2455C20.7671 32.4744 20.5557 32.662 20.3096 32.7931C20.0635 32.9243 19.7897 32.9951 19.5109 32.9998C19.232 33.0044 18.9561 32.9426 18.7058 32.8197C18.4555 32.6968 18.238 32.5163 18.0712 32.2928L15 28.1987"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
