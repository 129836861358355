import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import type { SubscriptionDto } from '@inphiz-shared/shared-models/src/F_Groups/Subscriptions/subscription-dto'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { QueryBuilder } from '@inphiz/core-utilities'

const MemberDto = new DataCollectionApiResponse<SubscriptionDto>()
export type GroupMemberData = typeof MemberDto

interface GroupMemberParams {
  groupHandle?: string
  itemPerPage?: number
  skip?: number
  searchTxt?: string
}

export function useGroupMemberList({
  groupHandle = '',
  itemPerPage = 20,
  skip = 0,
  searchTxt = '',
}: GroupMemberParams) {
  return useQuery<GroupMemberData>({
    queryKey: [QueryKey.groupMembers],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.subscriptionsUsers)
        .add('groupHandle', groupHandle)
        .add('cursor', skip)
        .add('take', itemPerPage)
        .add('filter', searchTxt)
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
    enabled: groupHandle !== '',
  })
}
