import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { ApiResponse } from '@inphiz-shared/shared-models/src/Api/api-response'
import { SensitiveDataDto } from '@inphiz-shared/shared-models/src/SensitiveData/sensitive-data-dto'
import { QueryBuilder } from '@inphiz/core-utilities'

const SensitiveItemDto = new SensitiveDataDto()
export type SensitiveItem = typeof SensitiveItemDto

const SensitiveDto = new ApiResponse()
export type SensitiveList = typeof SensitiveDto

interface Data extends SensitiveList {
  data?: SensitiveItem[]
}

export function useSensitiveInfo(handle: string) {
  return useQuery<Data>({
    queryKey: [QueryKey.getSensitiveInfo, handle],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.emergencyContacts.deleteEmergencyContacts).add(
        'OwnerHandle',
        handle,
      )
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
    enabled: false,
  })
}
