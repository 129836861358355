import { useTranslation } from 'react-i18next'
import { BorderButton, CustomButton, CustomText, ExperienceItem, TimeLine } from '../../component'
import {
  BlueDraftIcon,
  DraftCheck2Icon,
  DraftCheckIcon,
  DraftIssuedIcon,
  DraftShareIcon,
} from '../../icons'

const step = [
  {
    icon: <BlueDraftIcon />,
    title: 'Utkast skapad',
    description: '2024-10-01 \nPernilla Mårtensson',
    active: true,
  },
  {
    icon: <DraftShareIcon />,
    title: 'Skickad för signering',
    description: '[YYYY-MM-DD]\n[Admin / skapare]',
    active: false,
  },
  {
    icon: <DraftCheckIcon />,
    title: 'Signering ansvarig',
    description: '[YYYY-MM-DD]\n[Ansvarig]',
    active: false,
  },
  {
    icon: <DraftCheck2Icon />,
    title: 'Kvittering anställd',
    description: '[YYYY-MM-DD]\n[Anställd]',
    active: false,
  },
  {
    icon: <DraftIssuedIcon />,
    title: 'Körtillstånd utfärdat',
    description: '',
    active: false,
  },
]

const experience = [
  {
    title: 'Körkort B',
  },
  {
    title: 'Kranförare',
  },
  {
    title: 'Grävmaskinförare',
  },
  {
    title: 'Körkort B',
  },
]

function DrivingLicenseDetails() {
  const { t } = useTranslation('common')

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">{t('drivingLicense')}</CustomText>
      <div className="flex flex-1 flex-col mt-6 px-5 bg-white rounded-lg border border-stroke h-[100%] overflow-hidden">
        <CustomText variant="lgPoppinsSemiBold" className="my-3">
          Körtillstånd för Carl Axberg
        </CustomText>
        <div className="flex-1 overflow-hidden overflow-y-auto scrollbar">
          <div className="flex flex-1 border border-stroke justify-between py-3 px-3 rounded-lg">
            <div className="flex-1 px-5 py-2">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <div className="flex flex-1 flex-col">
                  <div className="mb-5">
                    <CustomText variant="mdPoppinsBold">{t('fieldDrivingLicense')}</CustomText>
                    <CustomText className="mt-1" variant="mdPoppinsRegular">
                      Carl Axberg
                    </CustomText>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                      <CustomText variant="mdPoppinsBold">{t('fieldSection')}</CustomText>
                      <CustomText className="mt-1" variant="mdPoppinsRegular">
                        24100
                      </CustomText>
                    </div>
                    <div>
                      <CustomText variant="mdPoppinsBold">{t('fieldBusiness')}</CustomText>
                      <CustomText className="mt-1" variant="mdPoppinsRegular">
                        Inphiz
                      </CustomText>
                    </div>
                  </div>
                  <div className="mb-5">
                    <CustomText variant="mdPoppinsBold">{t('fieldSignDrivingPermit')}</CustomText>
                    <CustomText className="mt-1" variant="mdPoppinsRegular">
                      Esko Airas
                    </CustomText>
                  </div>
                  <div className="mb-5">
                    <CustomText variant="mdPoppinsBold">{t('fieldTrainer')}</CustomText>
                    <CustomText className="mt-1" variant="mdPoppinsRegular">
                      Anna Johansson
                    </CustomText>
                  </div>
                  <div className="mb-5">
                    <CustomText variant="mdPoppinsBold">{t('fieldTools')}</CustomText>
                    <CustomText className="mt-1" variant="mdPoppinsRegular">
                      Arbete på station Stockholm Syd, Stockholm Nord och Stockholm City
                    </CustomText>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                      <CustomText variant="mdPoppinsBold">{t('fieldValidFrom')}</CustomText>
                      <CustomText className="mt-1" variant="mdPoppinsRegular">
                        2024-10-01
                      </CustomText>
                    </div>
                    <div>
                      <CustomText variant="mdPoppinsBold">{t('fieldValidTo')}</CustomText>
                      <CustomText className="mt-1" variant="mdPoppinsRegular">
                        2025-10-01
                      </CustomText>
                    </div>
                  </div>
                  <CustomText variant="mdPoppinsSemiBold">
                    {t('fieldDrivingLicenseRefers')}
                  </CustomText>
                  <div className="mt-4 flex gap-4 self-start">
                    {experience.map(i => (
                      <ExperienceItem title={i.title} />
                    ))}
                  </div>
                </div>
                <div className="flex flex-1 flex-col items-center justify-center">
                  <TimeLine step={step} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex gap-4 justify-center sm:justify-end py-4 rounded-md">
          <BorderButton
            children={t('back')}
            className="!px-14"
            textColor="text-textPrimary"
            onClick={() => {}}
          />
          <CustomButton
            children={t('cancelSigning')}
            backgroundColor="bg-secondary"
            textColor="text-textWhite"
            onClick={() => {}}
          />
        </div>
      </div>
    </div>
  )
}

export default DrivingLicenseDetails
