import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { CertificateItemDto } from '@inphiz-shared/shared-models/src/F_Certificates/certificate-item-dto'
import { QueryBuilder } from '@inphiz/core-utilities'

const CertificateItemsDTO = new CertificateItemDto()
export type CertificateItems = typeof CertificateItemsDTO

const CertificateItemsListDto = new DataCollectionApiResponse<CertificateItems>()
export type CertificateItemsData = typeof CertificateItemsListDto

interface Params {
  handle?: string
  certificateTypeHandle?: string
  itemPerPage?: number
  skip?: number
  status?: string
}

export function useCertificateItems({
  handle,
  certificateTypeHandle,
  itemPerPage,
  skip = 0,
  status,
}: Params) {
  return useQuery<CertificateItemsData>({
    queryKey: certificateTypeHandle
      ? [QueryKey.getCertificateItemsType, certificateTypeHandle]
      : [QueryKey.getCertificateItems, handle],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.employeesPerformance.certificateItems)
        .add('cursor', skip)
        .add('ParametersWithValues[UserHandle]', handle)
      if (certificateTypeHandle) {
        qb.add('ParametersWithValues[CertificateTypeHandle]', certificateTypeHandle)
      }
      if (itemPerPage) {
        qb.add('isorderascending', 'False')
        qb.add('take', itemPerPage)
      }
      if (status) {
        qb.add('ParametersWithValues[Status]', status)
      }
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
    enabled: certificateTypeHandle !== '',
  })
}
