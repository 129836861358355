export function StarIcon({ className = '' }) {
  return (
    <svg width={30} height={30} viewBox="0 0 30 30" className={className}>
      <defs>
        <clipPath id="clip-star">
          <rect width={30} height={30} />
        </clipPath>
      </defs>
      <g id="star" clipPath="url(#clip-star)">
        <path
          id="Shape_2216"
          data-name="Shape 2216"
          d="M732.593,3746.227l3.519,7.582h6.8a1.679,1.679,0,0,1,1.183,2.93l-5.9,5.85,3.293,7.568a1.8,1.8,0,0,1-2.555,2.242l-7.961-4.478-7.963,4.478a1.794,1.794,0,0,1-2.555-2.242l3.3-7.562-5.937-5.85a1.676,1.676,0,0,1,1.162-2.937h6.851l3.526-7.588a1.828,1.828,0,0,1,3.231.006Z"
          transform="translate(-715.954 -3743.946)"
          fill="#fdcb15"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
      </g>
    </svg>
  )
}
