import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { QueryBuilder } from '@inphiz/core-utilities'
import type { FeedbackGroupItem } from '../Feedback'
import type { filterItem } from '../../../screen/GroupList'

const GroupDataDto = new DataCollectionApiResponse<FeedbackGroupItem>()
export type GroupsData = typeof GroupDataDto

interface GroupsParams {
  itemPerPage?: number
  skip?: number
  searchTxt?: string
  filterData?: filterItem[]
}

export function useGroupList({
  itemPerPage = 20,
  skip = 0,
  searchTxt = '',
  filterData = [],
}: GroupsParams) {
  return useQuery<GroupsData>({
    queryKey: [QueryKey.groupList],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.newCategory)
        .add('cursor', skip)
        .add('take', itemPerPage)
        .add('filter', searchTxt)
        .add('isorderascending', 'False')
        .add('ParametersWithValues[IncludeAuthor]', 'True')
        .add('ParametersWithValues[HidePersonalGroups]', 'True')
      if (filterData?.length > 0 && filterData[0]?.selectedData) {
        qb.add('ParametersWithValues[SubscriptionFlowType]', filterData[0]?.selectedData)
      }
      if (filterData?.length > 0 && filterData[1]?.selectedData) {
        qb.add('ParametersWithValues[AllowedSubscriberContentType]', filterData[1]?.selectedData)
      }
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
