export function BlueDraftIcon({ className = '' }) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none" className={className}>
      <path
        d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
        fill="white"
      />
      <path
        d="M24 47C36.7025 47 47 36.7025 47 24C47 11.2975 36.7025 1 24 1C11.2975 1 1 11.2975 1 24C1 36.7025 11.2975 47 24 47Z"
        stroke="#569FE4"
        strokeWidth={2}
      />
      <path
        d="M24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42Z"
        fill="#569FE4"
      />
      <path
        d="M24.5564 25.8995L21.4844 26.3715L21.923 23.0712L29.8231 14.5857C30.1724 14.2106 30.646 14 31.1398 14C31.6337 14 32.1073 14.2106 32.4565 14.5857C32.8057 14.9608 33.0019 15.4696 33.0019 16C33.0019 16.5304 32.8057 17.0392 32.4565 17.4143L24.5564 25.8995Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.8966 26V32.6667C29.8966 33.0203 29.7657 33.3593 29.5329 33.6094C29.3001 33.8594 28.9844 34 28.6552 34H16.2414C15.9121 34 15.5964 33.8594 15.3636 33.6094C15.1308 33.3593 15 33.0203 15 32.6667V19.3333C15 18.9797 15.1308 18.6407 15.3636 18.3906C15.5964 18.1406 15.9121 18 16.2414 18H22.4483"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
