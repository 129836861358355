import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { queryClient } from '../../../App'
import { QueryBuilder } from '@inphiz/core-utilities'

function onSuccess() {
    queryClient.invalidateQueries({ queryKey: [QueryKey.groupList] })
}

interface DeleteMemberParams {
    groupHandles?: string
}

export function useDeleteGroup() {
    return useMutation({
        mutationFn: async (data: DeleteMemberParams) => {
            let params = {
                item: {
                    handle: data?.groupHandles,
                    contentType: "group"
                }
            }
            const qb = new QueryBuilder(APIConst.newCategory)
            const result = await APIFunctions.DELETE(qb.toString(), params)
            return result
        },
        onSuccess,
    })
}