import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { queryClient } from '../../../App'
import { QueryBuilder } from '@inphiz/core-utilities'

function onSuccess() {
    queryClient.invalidateQueries({ queryKey: [QueryKey.groupMembers] })
}

interface DeleteMemberParams {
    groupHandles?:string
    subscriberHandle?: string
}

export function useDeleteMember() {
    return useMutation({
        mutationFn: async (data: DeleteMemberParams) => {
            const qb = new QueryBuilder(APIConst.subscriptionMember)
                .add('GroupHandles', data.groupHandles)
                .add('SubscriberHandle', data.subscriberHandle)
            const result = await APIFunctions.DELETE(qb.toString(), {})
            return result
        },
        onSuccess,
    })
}