import { useInfiniteQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiRequest } from '@inphiz-shared/shared-models/src/Api/data-collection-api-request'
import { QueryBuilder } from '@inphiz/core-utilities'
import type { Group } from '../Social'
import { queryClient } from '../../../App'

export interface News {
  readonly handle: string
  readonly publishTime: string
  readonly title: string
  readonly content: string
  readonly contentType: string
  readonly summary: string
  readonly coverMedia: ReadonlyArray<{ readonly url: string }>
  readonly coverMediaHandles: ReadonlyArray<string>
  readonly likeCount: number
  readonly commentCount: number
  readonly contentFormat: string
  readonly authorName: string
  readonly metadata: {
    readonly IsCommentsEnabled: 'True' | 'False'
    readonly IsLikedByUser: 'True' | 'False'
    readonly IsLikesEnabled: 'True' | 'False'
  }
  readonly groups: [
    {
      readonly title: string
      handle: string
    },
  ]
  readonly type: string
}

export interface NewsList {
  items?: News[] | []
  readonly nextCursor: number
  totalCount?: number
}
interface FetchNewsProps {
  readonly selectedNewsGroupHandle: string | undefined
  readonly isEnabled?: boolean
}

const currentTakeItem = 10

export function useNewsQuery({ selectedNewsGroupHandle, isEnabled = true }: FetchNewsProps) {
  const tabData = queryClient.getQueryData([QueryKey.groupsList, 'news']) as Group[]
  const result = useInfiniteQuery({
    queryKey: [QueryKey.newsList, selectedNewsGroupHandle],
    queryFn: async ({ pageParam }) => {
      const rqb = new QueryBuilder(APIConst.getNews)
      let childOf: string[] = []
      if (selectedNewsGroupHandle) {
        childOf = [selectedNewsGroupHandle]
      }
      else {
        // filter out empty handles, group "all" handle is empty
        childOf = tabData.map(i => i.handle ?? '').filter(x => x !== '') ?? []
      }

      const rp = new DataCollectionApiRequest({
        childOf,
        take: currentTakeItem,
        cursor: pageParam.toString(),
        orderByProperty: 'PublishTime',
        isOrderAscending: false,
        onlyCount: false,
      })
      const url = rqb.addAsQueryParameters(rp).toString()
      const results = await APIFunctions.GET(url)
      const nextCursorValue = pageParam + 10
      const nextCursor = nextCursorValue >= results?.totalCount ? undefined : nextCursorValue
      return { ...results, nextCursor }
    },
    initialPageParam: 0,
    getNextPageParam: lastPage => lastPage.nextCursor,
    enabled: isEnabled,
  })
  return result
}
