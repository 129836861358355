import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { APIConst } from '@inphiz/api'
import Skeleton from 'react-loading-skeleton'
import type { ValueType } from 'rsuite/esm/Checkbox'
import { useTranslation } from 'react-i18next'
import { RoundArrowIcon, SettingIcon } from '../../../icons'
import { useCardAction } from '../../../packages/api-web'
import { CustomImg, CustomText } from '../../atoms'
import { getActionDescription } from '../../../helpers'

interface MyActionCardProps {
  title?: string
  actionType: string
  searchTxt: string
  className?: string
  stateNames: ValueType[]
  organizationUnitHandles: ValueType[]
  managerHandles: ValueType[]
  startDate: string
  endDate: string
}

export interface MyActionCardRef {
  refetchData: () => void
}

export const MyActionCard = forwardRef<MyActionCardRef, MyActionCardProps>(
  (
    {
      title,
      actionType,
      searchTxt,
      stateNames,
      organizationUnitHandles,
      managerHandles,
      className,
      startDate,
      endDate,
    },
    ref,
  ) => {
    const { t } = useTranslation('common')
    const [selectedIndex, setSelectedIndex] = useState(0)
    const { data, fetchNextPage, isFetching, refetch } = useCardAction({
      actionType,
      searchTxt,
      stateNames,
      organizationUnitHandles,
      managerHandles,
      startDate,
      endDate,
    })

    const myAction = useMemo(
      () => ({
        totalCount: data?.pages?.[0]?.totalCount,
        actionList: data?.pages?.flatMap(i => i.items) || [],
      }),
      [data],
    )

    const changeIndex = (increment: boolean) => {
      const newIndex = selectedIndex + (increment ? 1 : -1)
      if (newIndex < 0 || newIndex >= myAction.totalCount)
        return
      setSelectedIndex(newIndex)
      if (increment && newIndex === myAction.actionList.length - 1)
        fetchNextPage()
    }

    useImperativeHandle(ref, () => ({
      refetchData() {
        setSelectedIndex(0)
        setTimeout(refetch, 1000)
      },
    }))

    useEffect(() => {
      refetch()
    }, [])

    const currentAction = myAction.actionList[selectedIndex]
    const loadingState = isFetching || !currentAction

    if (!isFetching && myAction.actionList.length == 0) {
      return (
        <div className="flex flex-col border border-stroke rounded-md">
          <div className="px-5 py-3 border-b border-stroke">
            <CustomText variant="mdPoppinsSemiBold">{title ?? ''}</CustomText>
          </div>
          <div className="px-5 py-4">
            <div className="px-4 py-3 rounded-md border border-stroke">
              {isFetching && !selectedIndex ? (
                <>
                  <div className="flex items-center">
                    <Skeleton className="!h-9 !w-9 !rounded-full" />
                    <Skeleton width={150} className="!mx-2" />
                  </div>
                  <div className="!my-2">
                    <Skeleton className="!py-1.5 !mb-2.5" />
                    <Skeleton />
                  </div>
                </>
              ) : (
                <div className="flex items-center py-[30px]">
                  <SettingIcon className="fill-black h-20 w-20" />
                  <div className="flex-col items-center text-center">
                    <CustomText variant="mdPoppinsSemiBold" className="px-2">
                      {t('actionEmptyTitle')}
                    </CustomText>
                    <CustomText variant="mdPoppinsRegular" className="px-2">
                      {t('actionEmptyDes')}
                    </CustomText>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="flex flex-col border border-stroke rounded-md">
        <div className="px-5 py-3 border-b border-stroke">
          <CustomText variant="mdPoppinsSemiBold">{title ?? ''}</CustomText>
        </div>
        <div className="px-5 py-4">
          <div
            className={`px-4 py-3 rounded-md border-t border-b border-r border-stroke border-l-8 ${className}`}
          >
            {isFetching && !selectedIndex ? (
              <>
                <div className="flex items-center">
                  <Skeleton className="!h-9 !w-9 !rounded-full" />
                  <Skeleton width={150} className="!mx-2" />
                </div>
                <div className="!my-2">
                  <Skeleton className="!py-1.5 !mb-2.5" />
                  <Skeleton />
                </div>
              </>
            ) : (
              <>
                <div className="flex items-center">
                  <CustomImg
                    name={currentAction.title}
                    imgSrc={
                      currentAction.entityImageHandle
                        ? `${APIConst.baseURL}/${APIConst.mediaUpload}/${currentAction.entityImageHandle}`
                        : ''
                    }
                    altText="user_image"
                    className="h-9 w-9 rounded-full"
                    imgClassName="rounded-full"
                  />
                  <CustomText variant="mdPoppinsRegular" className="px-2">
                    {currentAction.title}
                  </CustomText>
                </div>
                <CustomText
                  wrapText={false}
                  variant="xlPoppinsSemiBold"
                  className="py-2 line-clamp-1"
                >
                  {getActionDescription(currentAction.type)?.title}
                </CustomText>
                <CustomText wrapText={false} variant="mdPoppinsRegular">
                  {currentAction.additionalData ?? '\t'}
                </CustomText>
              </>
            )}
          </div>
          <div className="flex items-center justify-between pt-3">
            <CustomText variant="smPoppinsRegular">{'\t'}</CustomText>
            {loadingState ? (
              <Skeleton height={20} width={100} />
            ) : (
              <div className="flex items-center gap-3">
                <CustomText variant="smPoppinsMedium">
                  {`${selectedIndex + 1}/${myAction.totalCount}`}
                </CustomText>
                <div className="flex items-center gap-1">
                  <button onClick={() => changeIndex(false)}>
                    <RoundArrowIcon
                      className={`rotate-180 ${selectedIndex > 0 ? 'text-secondary' : 'text-textSecondary'}`}
                    />
                  </button>
                  <button onClick={() => changeIndex(true)}>
                    <RoundArrowIcon
                      className={`${selectedIndex + 1 < myAction.totalCount ? 'text-secondary' : 'text-textSecondary'}`}
                    />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  },
)
