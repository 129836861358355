export function LockedIcon({ className = 'fill-[#fdcb15]' }) {
  return (
    <svg width={30} height={30} viewBox="0 0 30 30" className={className}>
      <defs>
        <clipPath id="clip-lock">
          <rect width={30} height={30} />
        </clipPath>
      </defs>
      <g id="lock" clipPath="url(#clip-lock)">
        <g id="Group_12869" data-name="Group 12869" transform="translate(3597.5 2813.747)">
          <g
            id="Interface-Essential_Lock_Unlock_lock-2"
            data-name="Interface-Essential / Lock/Unlock / lock-2"
            transform="translate(-3753.5 -4263.747)"
          >
            <g id="Group_140" transform="translate(-20)">
              <g id="lock-2">
                <path
                  id="Shape_467"
                  d="M198.5,1462.5h-.75v-2.75a6.75,6.75,0,0,0-13.5,0v2.75h-.75a2,2,0,0,0-2,2V1475a2,2,0,0,0,2,2h15a2,2,0,0,0,2-2v-10.5A2,2,0,0,0,198.5,1462.5Zm-7.5,9a2,2,0,1,1,2-2A2,2,0,0,1,191,1471.5Zm3.75-9a.5.5,0,0,0,.5-.5v-2.25a4.25,4.25,0,0,0-8.5,0V1462a.5.5,0,0,0,.5.5Z"
                  stroke="#fff"
                  strokeWidth={3}
                  fillRule="evenodd"
                />
              </g>
            </g>
          </g>
          <g id="Group_12671" data-name="Group 12671" transform="translate(5.886)">
            <rect
              id="Rectangle_8073"
              data-name="Rectangle 8073"
              width={7}
              height={6}
              transform="translate(-3592 -2797)"
              fill="#fff"
            />
            <g
              id="Interface-Essential_Lock_Unlock_lock-2-2"
              data-name="Interface-Essential / Lock/Unlock / lock-2"
              transform="translate(-3779.386 -4263.747)"
            >
              <g id="Group_140-2" data-name="Group_140">
                <g id="lock-2-2" data-name="lock-2">
                  <path
                    id="Shape_467-2"
                    data-name="Shape_467"
                    d="M198.5,1462.5h-.75v-2.75a6.75,6.75,0,0,0-13.5,0v2.75h-.75a2,2,0,0,0-2,2V1475a2,2,0,0,0,2,2h15a2,2,0,0,0,2-2v-10.5A2,2,0,0,0,198.5,1462.5Zm-7.5,9a2,2,0,1,1,2-2A2,2,0,0,1,191,1471.5Zm3.75-9a.5.5,0,0,0,.5-.5v-2.25a4.25,4.25,0,0,0-8.5,0V1462a.5.5,0,0,0,.5.5Z"
                    fillRule="evenodd"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
