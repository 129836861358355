import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { queryClient } from '../../../App'

function onSuccess() {
    queryClient.invalidateQueries({ queryKey: [QueryKey.groupList] })
}

export function usePostGroup() {
    return useMutation({
        mutationFn: async (data: any) => {
            const params = {
                item: data,
            }
            const result = await APIFunctions.POST(
                APIConst.newCategory,
                params,
            )
            return result
        },
        onSuccess
    })
}