import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import type { ValueType } from 'rsuite/esm/Checkbox'
import { useNavigate } from 'react-router-dom'
import {
  BorderButton,
  CustomButton,
  CustomDropDown,
  CustomInput,
  CustomText,
} from '../../component'
import { useManagerFilterList, useOrganizationUnits } from '../../packages/api-web'
import { useDepartmentList } from '../../packages/api-web/Employee'

function EmployeeCreate() {
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const checkBoxArray = [
    { handle: 1, title: t('regulatoryCompliance') },
    { handle: 2, title: t('requiresAttachment') },
  ]
  const [selectCheck, setSelectCheck] = useState<ValueType[]>()
  const { isLoading: isLoadOrganization, data: organizationList } = useOrganizationUnits()
  const { isLoading: isDepartment, data: departmentList } = useDepartmentList()
  const {
    data: managerList,
    isLoading: isManagerList,
    refetch: refetchManagerList,
  } = useManagerFilterList({ filterTxt: '' })

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">Employee Create</CustomText>
      <div className="flex flex-1 flex-col mt-6 px-5 bg-white rounded-lg border border-stroke h-[100%] overflow-hidden">
        <div className="flex-1 overflow-hidden overflow-y-auto scrollbar">
          <div className="border border-stroke rounded-lg mt-3">
            <div className="py-3 px-5 border-b border-b-stroke">
              <CustomText variant="mdPoppinsSemiBold">Create</CustomText>
            </div>
            <div className="flex-1 p-5 overflow-y-auto scrollbar">
              <div className="grid grid-cols-2 gap-3 mb-3">
                <CustomInput title="First name" value="" onChange={(e) => {}} />
                <CustomInput title="Last name" value="" onChange={(e) => {}} />
              </div>
              <CustomInput title="Username" value="" onChange={(e) => {}} className="mb-3" />
              <div className="grid grid-cols-2 gap-3 mb-3">
                <CustomInput title="Business mobile" value="" onChange={(e) => {}} />
                <CustomInput title="Private mobile" value="" onChange={(e) => {}} />
              </div>
              <div className="grid grid-cols-2 gap-3 mb-3">
                <CustomDropDown
                  values={[]}
                  className="!mt-3"
                  title="Organization"
                  options={organizationList?.items ?? []}
                  labelField="title"
                  valueField="handle"
                  onChange={(values) => {}}
                  placeholder="Select"
                  searchable
                />
                <CustomDropDown
                  values={[]}
                  className="!mt-3"
                  title="Department"
                  options={departmentList?.items ?? []}
                  labelField="title"
                  valueField="handle"
                  onChange={(values) => {}}
                  placeholder="Select"
                  searchable
                />
              </div>
              <div className="grid grid-cols-2 gap-3 mb-3">
                <CustomInput title="State" value="" onChange={(e) => {}} />
                <CustomInput title="City" value="" onChange={(e) => {}} />
              </div>
              <div className="grid grid-cols-2 gap-3 mb-3">
                <CustomDropDown
                  values={[]}
                  className="!mt-3"
                  title="Job title"
                  options={[
                    {
                      handle: 1,
                      title: 'Leanne Graham',
                    },
                    {
                      handle: 2,
                      title: 'Ervin Howell',
                    },
                  ]}
                  labelField="title"
                  valueField="handle"
                  onChange={(values) => {}}
                  placeholder="Select"
                />
                <CustomDropDown
                  values={[]}
                  className="!mt-3"
                  title="Manager"
                  options={managerList?.items ?? []}
                  labelField="displayName"
                  valueField="handle"
                  onChange={(values) => {}}
                  placeholder="Select"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-4 justify-end items-center py-4">
          <div className="flex gap-4 flex-wrap">
            <BorderButton
              children={t('cancel')}
              className="!px-2 sm:!px-5 flex-1"
              textColor="text-textPrimary"
              onClick={() => navigate(-1)}
            />
            <CustomButton
              children={t('save')}
              className="flex-auto"
              backgroundColor="bg-secondary"
              textColor="text-textWhite"
              onClick={() => {}}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeeCreate
