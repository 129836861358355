export function MyProfile({ className = '' }) {
  return (
    <svg width={25} height={25} viewBox="0 0 25 25" className={className}>
      <defs>
        <clipPath id="clip-My_profile">
          <rect width={25} height={25} />
        </clipPath>
      </defs>
      <g id="My_profile" data-name="My profile" clipPath="url(#clip-My_profile)">
        <g id="Regular_297" data-name="Regular 297" transform="translate(-465.5 -2121.5)">
          <path
            id="Oval_454"
            data-name="Oval 454"
            d="M478,2137a5.25,5.25,0,1,0-5.25-5.25A5.251,5.251,0,0,0,478,2137Z"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
          />
          <path
            id="Shape_1194"
            data-name="Shape 1194"
            d="M484.913,2142.876a9.746,9.746,0,0,0-13.826,0"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
          />
          <path
            id="Oval_455"
            data-name="Oval 455"
            d="M478,2145.25A11.25,11.25,0,1,0,466.75,2134,11.25,11.25,0,0,0,478,2145.25Z"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
          />
        </g>
      </g>
    </svg>
  )
}
