import {
  AddSocialPost,
  Campaign,
  CampaignStatistics,
  CertificateType,
  CertificateTypeInfo,
  Chat,
  ChatDetails,
  CreateCampaign,
  CreateCertificate,
  CreateCertificateType,
  CreateEquipmentCateScreen,
  CreateNewDrivingPermit,
  CreateRoleMetaDataType,
  Dashboard,
  DashboardNewsCreate,
  DashboardNewsList,
  DetailsCampaign,
  DrivingLicense,
  DrivingLicenseDetails,
  EmployeeCreate,
  EmployeeDetails,
  EmployeeList,
  EquipmentCategory,
  EquipmentCreate,
  EquipmentList,
  Experiences,
  Feedback,
  GroupCreate,
  GroupList,
  Login,
  MyDay,
  NewDetails,
  News,
  RoleMetaData,
  RolePositionEdit,
  RolePositionList,
  RoleSpecializationEdit,
  RoleSpecializationsList,
  SocialDetails,
  SocialScreen,
} from './screen'
import { MainTemplate } from './component'
import { UserProvider } from './contexts/UserContext'

const MainRoutes = {
  path: '/',
  element: (
    <UserProvider>
      <MainTemplate />
    </UserProvider>
  ),
  children: [
    {
      path: '/dashboard',
      element: <Dashboard />,
    },
    {
      path: '/social',
      element: <SocialScreen />,
    },
    {
      path: '/news',
      element: <News />,
    },
    {
      path: '/socialdetails',
      element: <SocialDetails socialHandle="" typeHandle="" />,
    },
    {
      path: '/newdetails',
      element: <NewDetails newsHandle="" typeHandle="" />,
    },
    {
      path: '/chat',
      element: <Chat />,
    },
    {
      path: '/create_post',
      element: <AddSocialPost />,
    },
    {
      path: '/my_day',
      element: <MyDay />,
    },
    {
      path: '/chatdetails',
      element: <ChatDetails />,
    },
    {
      path: '/feedback',
      element: <Feedback />,
    },
    {
      path: '/feedback/campaign',
      element: <Campaign />,
    },
    {
      path: '/feedback/campaign/create',
      element: <CreateCampaign />,
    },
    {
      path: '/feedback/campaign/:id',
      element: <DetailsCampaign />,
    },
    {
      path: '/feedback/campaign/statistics/:id',
      element: <CampaignStatistics />,
    },
    {
      path: '/employee_list',
      element: <EmployeeList />,
    },
    {
      path: '/employee/create',
      element: <EmployeeCreate />,
    },
    {
      path: '/employee/:id',
      element: <EmployeeDetails />,
    },
    {
      path: '/experiences_list',
      element: <Experiences />,
    },
    {
      path: '/experiences/certificate',
      element: <CreateCertificate />,
    },
    {
      path: '/experiences/type',
      element: <CertificateType />,
    },
    {
      path: '/experiences/create_type',
      element: <CreateCertificateType />,
    },
    {
      path: '/experiences/create_type/info',
      element: <CertificateTypeInfo />,
    },
    {
      path: '/role_management/position',
      element: <RolePositionList />,
    },
    {
      path: '/role_management/specializationsList',
      element: <RoleSpecializationsList />,
    },
    {
      path: '/role_management/metadata',
      element: <RoleMetaData />,
    },
    {
      path: '/role_management/metadata/create',
      element: <CreateRoleMetaDataType />,
    },
    {
      path: '/equipment_list',
      element: <EquipmentList />,
    },
    {
      path: '/equipment/category',
      element: <EquipmentCategory />,
    },
    {
      path: '/equipment/category/create',
      element: <CreateEquipmentCateScreen />,
    },
    {
      path: '/equipment/create',
      element: <EquipmentCreate />,
    },
    {
      path: '/equipment/edit/:id',
      element: <EquipmentCreate />,
    },
    {
      path: '/dashboard/news_list',
      element: <DashboardNewsList />,
    },
    {
      path: '/dashboard/create/news_item',
      element: <DashboardNewsCreate />,
    },
    {
      path: '/news/:id',
      element: <DashboardNewsCreate />,
    },
    {
      path: '/role_management/position/job-title/:id',
      element: <RolePositionEdit />,
    },
    {
      path: '/role_management/specialization/job-role/:id',
      element: <RoleSpecializationEdit />,
    },
    {
      path: '/driving_license',
      element: <DrivingLicense />,
    },
    {
      path: '/driving/permit',
      element: <CreateNewDrivingPermit />,
    },
    {
      path: '/driving/permit/:id',
      element: <DrivingLicenseDetails />,
    },
    {
      path: '/group_list',
      element: <GroupList />,
    },
    {
      path: '/group/create',
      element: <GroupCreate />,
    },
    {
      path: '/group/:id',
      element: <GroupCreate />,
    },
  ],
}

const AuthRoutes = {
  path: '/',
  element: (
    <UserProvider>
      <Login />
    </UserProvider>
  ),
}

export { AuthRoutes, MainRoutes }
