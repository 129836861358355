import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { QueryBuilder } from '@inphiz/core-utilities'

export interface FeedbackGroupItem {
  title: string
  description: string
  ownerHandle: any
  ownerContentType: any
  authorHandle: any
  author: any
  allowedGroupSubscribers: number
  subscriptionFlowType: number
  permissions: any
  createdTime: string
  modifiedTime: string
  isDeleted: boolean
  deletedTime: any
  handle: string
  contentType: string
}

const FeedbackGroupDataDto = new DataCollectionApiResponse<FeedbackGroupItem>()
type FeedbackGroupData = typeof FeedbackGroupDataDto

export function useFeedbackGroupList(
  handle?: string,
  searchTxt?: string,
  subscriberContentType?: string,
) {
  return useQuery<FeedbackGroupData>({
    queryKey: handle ? [QueryKey.feedbackGroup, handle] : [QueryKey.feedbackGroup],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.getFeedbackGroups)
        .add('UseContinuationToken', 'False')
        .add('Cursor', 0)
        .add('Take', 2147483647)
        .add('IncludeChildCount', 0)
        .add('OrderByProperty', 'Title')
        .add('IsOrderAscending', 'True')
        .add('OnlyCount', 'False')
        .add('ParametersWithValues[HidePersonalGroups]', 'True')
        .add(
          'ParametersWithValues[AllowedSubscriberContentType]',
          subscriberContentType || 'feedback-campaign',
        )
      if (handle) {
        qb.add('ParametersWithValues[HasSubscriberHandle]', handle)
      }
      if (searchTxt) {
        qb.add('Filter', searchTxt)
      }
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
    staleTime: Number.POSITIVE_INFINITY,
  })
}
