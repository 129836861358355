import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import type { SubscriptionDto } from '@inphiz-shared/shared-models/src/F_Groups/Subscriptions/subscription-dto'
import { queryClient } from '../../../App'

function onSuccess() {
  queryClient.invalidateQueries({ queryKey: [QueryKey.groupMembers] })
}

export function usePostMember() {
  return useMutation({
    mutationFn: async (data: SubscriptionDto[]) => {
      const params = {
        subscriptions: data,
      }
      const result = await APIFunctions.POST(APIConst.subscriptionMember, params)
      return result
    },
    onSuccess,
  })
}
