export function PhoneIcon({ className = '' }) {
  return (
    <svg
      width="24.000000pt"
      height="24.000000pt"
      viewBox="0 0 24.000000 24.000000"
      className={className}
    >
      <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M15 220 c-16 -17 -17 -25 -7 -58 15 -49 63 -106 118 -138 50 -29 86 -27 105 7 9 18 7 26 -15 47 -23 24 -27 25 -51 12 -50 -27 -102 25 -74 76 11 21 10 27 -11 49 -28 30 -41 31 -65 5z" />
      </g>
    </svg>
  )
}
