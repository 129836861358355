import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import type { MultiDropdownParams } from '../../component'
import {
  CustomButton,
  CustomInput,
  CustomText,
  MultiSelectDropDown,
  Spinner,
} from '../../component'
import ScheduleItem from '../../component/organisms/ScheduleItem'
import CustomDatePicker from '../../component/atoms/CustomDatePicker'
import type { SchedulesParam } from '../../packages/api-web'
import { useCampaignCreate, useFeedbackGroupList } from '../../packages/api-web'

function CreateCampaign() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { isLoading, data: groupData } = useFeedbackGroupList()
  const { mutate, isPending } = useCampaignCreate()
  const [scheduleList, setScheduleList] = useState<SchedulesParam[]>([
    {
      title: '',
      description: '',
      startAt: new Date(),
      endAt: null,
      cron: '0 0 15 1/1 * ? *',
      jobTitleHandles: [],
      surveys: [],
    },
  ])
  const [campaignName, setCampaignName] = useState<string>('')
  const [selectedGroups, setSelectedGroups] = useState<string[]>([])
  const [campaignDescription, setCampaignDescription] = useState<string>('')
  const [startDate, setStartDate] = useState<Date | null>(new Date())
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [errorString, setErrorString] = useState<string[]>([])

  const groupFilterData = useMemo(() => {
    return (groupData?.items ?? []).map(item => ({
      label: item?.title,
      value: item?.handle,
    })) as MultiDropdownParams[]
  }, [groupData])

  if (isLoading)
    return <Spinner />

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">{t('campaigns')}</CustomText>
      <div className="mt-5 overflow-y-auto scrollbar">
        <CustomText variant="xlPoppinsMedium">{t('createNewCampaign')}</CustomText>
        <div className="my-5 grid grid-cols-1 lg:grid-cols-5 items-center gap-5 bg-white p-5 rounded-md">
          <CustomInput
            title={t('campaignName')}
            className={`mt-[5px] ${!campaignName && errorString.length > 0 ? 'outline-textDanger' : ''}`}
            value={campaignName}
            onChange={(e) => {
              setCampaignName(e.target.value)
            }}
          />
          <MultiSelectDropDown
            title={t('campaignGroup')}
            value={selectedGroups}
            data={groupFilterData ?? []}
            onChange={(value) => {
              setSelectedGroups(value as string[])
            }}
          />
          <CustomInput
            title={t('campaignDescription')}
            className={`mt-[5px] ${!campaignDescription && errorString.length > 0 ? 'outline-textDanger' : ''}`}
            value={campaignDescription}
            onChange={(e) => {
              setCampaignDescription(e.target.value)
            }}
          />
          <CustomDatePicker
            title={t('startDate')}
            minDate={new Date()}
            value={startDate}
            onChange={(value) => {
              if (value) {
                setStartDate(value)
              }
            }}
          />
          <CustomDatePicker
            title={t('endDate')}
            minDate={startDate}
            value={endDate}
            onChange={setEndDate}
          />
        </div>
        <div className="flex flex-col">
          {scheduleList.map((item, index) => (
            <ScheduleItem
              key={index}
              item={item}
              index={index}
              errorString={errorString}
              handleEdit={(itemIndex, field, value) => {
                const data = [...scheduleList]
                data[itemIndex] = {
                  ...data[itemIndex],
                  [field]: value,
                }
                setScheduleList(data)
              }}
            />
          ))}
        </div>
        <CustomButton
          children={t('addNewSchedule')}
          className="self-start bg-secondary"
          onClick={() => {
            const array = [...scheduleList]
            array.push({
              title: '',
              description: '',
              startAt: new Date(),
              endAt: null,
              cron: '0 0 15 1/1 * ? *',
              jobTitleHandles: [],
              surveys: [],
            })
            setScheduleList(array)
          }}
        />
        {errorString.length > 0 && (
          <ul className="mt-5 list-disc pl-4">
            {errorString.map((i, index) => (
              <li key={index} className="text-textDanger">
                {i}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="relative flex justify-center sm:justify-end bg-white p-4 rounded-md mt-5">
        <CustomButton
          children={t('cancel')}
          backgroundColor="bg-bthDisable"
          textColor="text-textPrimary"
          onClick={() => {
            navigate(-1)
          }}
        />
        <CustomButton
          children={t('save')}
          className="ml-3"
          backgroundColor="bg-primary"
          textColor="text-textWhite"
          onClick={() => {
            if (campaignName && campaignDescription && scheduleList.every(i => i.title)) {
              const data = {
                title: campaignName,
                description: campaignDescription,
                startAt: startDate,
                endAt: endDate,
                groupHandles: selectedGroups,
                schedules: scheduleList.map((i) => {
                  return {
                    ...i,
                    startAt: i.startAt,
                    endAt: i.endAt,
                  }
                }),
              }
              mutate(data, {
                onSuccess: (data) => {
                  navigate(-1)
                },
              })
            }
            else {
              const errorArray = []
              if (!campaignName) {
                errorArray.push(t('errorCampaign'))
              }
              if (!campaignDescription) {
                errorArray.push(t('errorDescription'))
              }
              scheduleList.forEach((i, index) => {
                if (!i.title) {
                  errorArray.push(`${t('schedule')} ${index}: ${t('errorSchedule')}`)
                }
              })
              setErrorString(errorArray)
            }
          }}
        />
      </div>
      {isPending && <Spinner />}
    </div>
  )
}

export default CreateCampaign
