import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import type { EmergencyContactsDto } from '@inphiz-shared/shared-models/src/SensitiveData/emergency-contacts-dto'

export function usePostEmergencyNumber() {
  return useMutation({
    mutationFn: async (params: EmergencyContactsDto) => {
      const data = {
        emergencyContacts: params,
      }
      const result = await APIFunctions.POST(APIConst.emergencyContacts.emergencyContacts, data)
      return result
    },
  })
}
