import { useParams } from 'react-router-dom'
import {
  CustomText,
  EmployeeActions,
  EmployeeCertificate,
  EmployeeInfo,
  EmployeePerformance,
  EmployeeStatus,
} from '../../component'

function EmployeeDetails() {
  const { id } = useParams()

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">Info</CustomText>
      <div className="flex flex-1 flex-col mt-6 h-[100%] overflow-hidden overflow-y-auto">
        <div className="flex-1 overflow-hidden overflow-y-auto scrollbar">
          <div className="lg:gap-3 grid grid-cols-1 lg:grid-cols-3 p-5 rounded-lg border border-stroke bg-white">
            {/* User profile section */}
            <EmployeeInfo handle={id ?? ''} />
            <EmployeeStatus handle={id ?? ''} />
          </div>
          <div className="flex flex-col mt-3 p-5 rounded-lg border border-stroke bg-white">
            <EmployeeActions handle={id ?? ''} />
          </div>
          <div className="flex flex-col mt-3 p-5 h-[500px] rounded-lg border border-stroke bg-white">
            <EmployeePerformance handle={id ?? ''} />
          </div>
          <div className="mt-3 p-5 rounded-lg border border-stroke bg-white">
            <EmployeeCertificate handle={id ?? ''} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeeDetails
