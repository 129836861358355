import type { KeyboardEvent } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  BorderButton,
  CustomButton,
  CustomMenu,
  CustomPagination,
  CustomTable,
  CustomText,
  TextWithIcon,
} from '../../component'
import type { filterItem } from '../EquipmentList'
import { CloseIcon, RightIcon, SearchIcon } from '../../icons'

const DataItem = [
  {
    displayName: 'Pernilla Mårtensson',
    positionType: 'Mekaniker',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 0,
    download: false,
    profileImageHandle: null,
  },
  {
    displayName: 'Esko Airas',
    positionType: 'Kontorspersonal',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 1,
    download: true,
    profileImageHandle: null,
  },
  {
    displayName: 'Anna Johansson',
    positionType: 'Maskinförare',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 2,
    download: false,
    profileImageHandle: null,
  },
  {
    displayName: 'Amira Hassan',
    positionType: 'Kontorspersonal',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 3,
    download: true,
    profileImageHandle: null,
  },
  {
    displayName: 'Carlos González',
    positionType: 'Lokförare',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 4,
    download: false,
    profileImageHandle: null,
  },
  {
    displayName: 'Peter Olsson',
    positionType: 'Drifttekniker',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 5,
    download: true,
    profileImageHandle: null,
  },
  {
    displayName: 'Li Wei',
    positionType: 'Drifttekniker',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 6,
    download: false,
    profileImageHandle: null,
  },
  {
    displayName: 'Pernilla Mårtensson',
    positionType: 'Mekaniker',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 0,
    download: false,
    profileImageHandle: null,
  },
  {
    displayName: 'Esko Airas',
    positionType: 'Kontorspersonal',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 1,
    download: true,
    profileImageHandle: null,
  },
  {
    displayName: 'Anna Johansson',
    positionType: 'Maskinförare',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 2,
    download: false,
    profileImageHandle: null,
  },
  {
    displayName: 'Amira Hassan',
    positionType: 'Kontorspersonal',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 3,
    download: true,
    profileImageHandle: null,
  },
  {
    displayName: 'Carlos González',
    positionType: 'Lokförare',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 4,
    download: false,
    profileImageHandle: null,
  },
  {
    displayName: 'Peter Olsson',
    positionType: 'Drifttekniker',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 5,
    download: true,
    profileImageHandle: null,
  },
  {
    displayName: 'Li Wei',
    positionType: 'Drifttekniker',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 6,
    download: false,
    profileImageHandle: null,
  },
  {
    displayName: 'Pernilla Mårtensson',
    positionType: 'Mekaniker',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 0,
    download: false,
    profileImageHandle: null,
  },
  {
    displayName: 'Esko Airas',
    positionType: 'Kontorspersonal',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 1,
    download: true,
    profileImageHandle: null,
  },
  {
    displayName: 'Anna Johansson',
    positionType: 'Maskinförare',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 2,
    download: false,
    profileImageHandle: null,
  },
  {
    displayName: 'Amira Hassan',
    positionType: 'Kontorspersonal',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 3,
    download: true,
    profileImageHandle: null,
  },
  {
    displayName: 'Carlos González',
    positionType: 'Lokförare',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 4,
    download: false,
    profileImageHandle: null,
  },
  {
    displayName: 'Peter Olsson',
    positionType: 'Drifttekniker',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 5,
    download: true,
    profileImageHandle: null,
  },
  {
    displayName: 'Li Wei',
    positionType: 'Drifttekniker',
    department: '24100',
    issuer: 'Carl Axberg',
    validity: '2024-10-01 – 2026-09-01',
    certificateStatus: 6,
    download: false,
    profileImageHandle: null,
  },
]

function DrivingLicense() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [filterData, setFilterData] = useState<filterItem[]>([
    {
      id: 1,
      header: t('positionType'),
      data: [],
      selectedData: null,
      isSearch: false,
    },
    {
      id: 2,
      header: t('department'),
      data: [],
      selectedData: null,
      isSearch: true,
    },
    {
      id: 3,
      header: t('issuer'),
      data: [],
      selectedData: null,
      isSearch: true,
    },
    {
      id: 4,
      header: t('status'),
      data: [],
      selectedData: null,
      isSearch: true,
    },
  ])
  const [itemPerPage, setItemPage] = useState(20)
  const [skipItem, setSkipItem] = useState(0)
  const [searchTxt, setSearchTxt] = useState('')

  const tableHeaderList = [
    { title: t('name'), id: 1 },
    { title: t('positionType'), id: 2 },
    { title: t('department'), id: 3 },
    { title: t('issuer'), id: 4 },
    { title: t('periodOfValidity'), id: 5 },
    { title: t('status'), id: 6 },
    { title: '', id: 7 },
  ]

  const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    // if (event.key === 'Enter') {
    // }
  }

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">{t('drivingLicense')}</CustomText>
      <div className="mt-6 px-5 py-3 bg-white rounded-lg flex items-center justify-between border border-stroke">
        <div className="flex items-center gap-3 flex-wrap">
          {filterData.map((item, index) => {
            return (
              <div className="flex items-center gap-3" key={index}>
                <CustomMenu
                  // isSearchLoader={isEmployeeLoad}
                  menuList={item?.data ?? []}
                  selectedValue={item?.selectedData ?? null}
                  onClick={(value) => {
                    const updateItem = { ...item, selectedData: value?.handle }
                    const newFilter: any = [...filterData]
                    newFilter[index] = updateItem
                    setFilterData(newFilter)
                  }}
                  buttonComponent={(
                    <div className="flex">
                      <TextWithIcon
                        text={item?.header}
                        rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-4" />}
                        textVariant="smPoppinsRegular"
                      />
                      {item?.selectedData && (
                        <>
                          <div className="absolute -top-4 right-1 bg-primary h-4 w-4 rounded-full justify-center items-center flex">
                            <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                              1
                            </CustomText>
                          </div>
                          <button
                            onClick={() => {
                              const updateItem = { ...item, selectedData: null }
                              const newFilter: any = [...filterData]
                              newFilter[index] = updateItem
                              setFilterData(newFilter)
                            }}
                          >
                            <CloseIcon className="h-5" />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                  {...(item?.isSearch && {
                    onSearch: (e) => {
                      if (e?.length > 3) {
                        // setSearchEmployee(e)
                      }
                    },
                  })}
                />
                <div className="h-8 bg-black/10 w-[1px]" />
              </div>
            )
          })}
        </div>
        <BorderButton
          children={t('filter')}
          className="px-7 self-end"
          // onClick={() => { }}
        />
      </div>
      <div className="mt-6 py-6 px-5 bg-white rounded-lg border border-stroke flex flex-col flex-1 overflow-hidden">
        <div className="flex flex-col sm:flex-row mb-4 gap-4">
          <CustomText className="mr-auto self-center" wrapText={false} variant="xlPoppinsBold">
            {`${t('total')}: ${DataItem.length}`}
          </CustomText>
          <div className="flex flex-1 items-center justify-between">
            <div className="flex items-center w-auto gap-4">
              <SearchIcon />
              <input
                type="text"
                value={searchTxt}
                placeholder={t('searchPlaceholder')}
                className="outline-0 flex-1 min-w-[100px]"
                onChange={e => setSearchTxt(e.target.value)}
                onKeyDown={handleInputKeyDown}
              />
              {searchTxt && (
                <button
                  onClick={() => {
                    setSearchTxt('')
                    setTimeout(() => {
                      // refetch()
                    }, 1000)
                  }}
                >
                  <CloseIcon className="h-5" />
                </button>
              )}
            </div>
            <CustomButton
              children={t('addNewDriving')}
              textVariant="smPoppinsMedium"
              className="px-4 sm:px-8 bg-secondary"
              onClick={() => {
                navigate('/driving/permit')
              }}
            />
          </div>
        </div>
        <div className="flex flex-col overflow-hidden rounded-lg border border-stroke">
          <div className="flex-1 overflow-x-auto overflow-y-auto scrollbar bg-white">
            <CustomTable
              tableHeaderList={tableHeaderList}
              tableDataList={DataItem.slice(skipItem, skipItem + itemPerPage)}
              displayKeys={[
                'displayName',
                'positionType',
                'department',
                'issuer',
                'validity',
                'certificateStatus',
                'download',
              ]}
              // onClick={(item) => {
              //     console.log("item press")
              //     navigate(`/driving/permit/${1}`)
              // }}
              // onDownloadClick={(item) => {
              //     console.log("Download press")
              // }}
            />
          </div>
          <CustomPagination
            totalItem={DataItem.length}
            itemPerPage={itemPerPage}
            onChange={(value) => {
              setItemPage(value)
            }}
            onPagePress={(index) => {
              const skipItem = (index - 1) * itemPerPage
              setSkipItem(skipItem)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default DrivingLicense
