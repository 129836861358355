import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import type { DeleteSensitiveDataApiRequest } from '@inphiz-shared/shared-models/src/SensitiveData/delete-sensitive-data-api-request'
import { QueryBuilder } from '@inphiz/core-utilities'

export function useDeleteSSN() {
  return useMutation({
    mutationFn: async (params: DeleteSensitiveDataApiRequest) => {
      const qb = new QueryBuilder(APIConst.emergencyContacts.deleteEmergencyContacts)
        .add('Type', params?.type)
        .add('OwnerHandle', params?.ownerHandle)
      const result = await APIFunctions.DELETE(qb.toString(), {})
      return result
    },
  })
}
