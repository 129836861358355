export function CheckboxCheck({ className = '' }) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" className={className}>
      <rect x={0.5} y={3.5} width={21} height={21} rx={3.5} fill="white" stroke="#DFE1E4" />
      <path
        d="M24 1L11.9264 18.2455C11.7671 18.4744 11.5557 18.662 11.3096 18.7931C11.0635 18.9243 10.7897 18.9951 10.5109 18.9998C10.232 19.0044 9.95609 18.9426 9.70579 18.8197C9.45548 18.6968 9.23797 18.5163 9.07119 18.2928L6 14.1987"
        stroke="#333333"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
