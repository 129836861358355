import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst, QueryKey } from '@inphiz/api'
import type { SetCertificateItemStatusApiRequest } from '@inphiz-shared/shared-models/src/F_Certificates/set-certificate-item-status-api-request'
import { queryClient } from '../../../App'

interface params extends SetCertificateItemStatusApiRequest {
  certificateTypeHandle?: string
}

export function usePostCertificateStatus() {
  return useMutation({
    mutationFn: async (params: params) => {
      const result = await APIFunctions.POST(
        APIConst.employeesPerformance.addArchiveCertificate,
        params,
      )
      return result
    },
    onSuccess(_: any, variables: any) {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.getCertificateItemsType, variables.certificateTypeHandle],
      })
    },
  })
}
