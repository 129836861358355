import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useRef, useState } from 'react'
import type { CertificateItemDto } from '@inphiz-shared/shared-models/src/F_Certificates/certificate-item-dto'
import { Statuses } from '@inphiz-shared/shared-models/src/F_Certificates/UserCertificateConstants/statuses'
import { CustomAlert, CustomButton, CustomDropDown, CustomText, Spinner } from '../../atoms'
import CustomDatePicker from '../../atoms/CustomDatePicker'
import type { CertificateItem, CertificateItems } from '../../../packages/api-web/Employee'
import {
  useCertificateList,
  usePostCertificateItem,
  usePostCertificateStatus,
  usePostRenewCetificate,
  usePutCertificateItem,
} from '../../../packages/api-web/Employee'
import { CloseIcon } from '../../../icons'
import type { UploadResponse } from '../../../packages/api-web'
import { useUploadMedia } from '../../../packages/api-web'
import { DeleteReasonsModal } from '../DeleteReasonsModal'

interface GroupMembersProps {
  userHandle?: string
  handle?: string
  onCloseAlert?: () => void
  selectedItem?: CertificateItems
}

export function AddCertificateEmployee({
  userHandle,
  handle,
  onCloseAlert,
  selectedItem,
}: GroupMembersProps) {
  const { t } = useTranslation('common')
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [selectedCertificate, setSelectedCertificate] = useState<CertificateItem | any>()
  const [selectedImages, setSelectedImages] = useState<HTMLInputElement[]>([])
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date())
  const [selectedEndDate, setSelectedEndDate] = useState<Date>()
  const [errorString, setErrorString] = useState<string[]>([])
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [isDeleteMode, setIsDeleteMode] = useState<boolean>(false)
  const { data: certificateList } = useCertificateList()
  const { mutate: uploadMediaMute, isPending: isUploadMediaPending } = useUploadMedia()
  const { mutate: postCertificateItem, isPending: isCertificateItemPending }
    = usePostCertificateItem()
  const { mutate: updateCertificateItem, isPending: isUpdateCertificateItemPending }
    = usePutCertificateItem()
  const { mutate: postCertificateStatus, isPending: isPostCertificateStatus }
    = usePostCertificateStatus()
  const { mutate: postReNewCertificate, isPending: isPostReNewCertificate }
    = usePostRenewCetificate({
      handle: selectedCertificate?.certificateItemHandle,
    })

  useEffect(() => {
    if (selectedItem) {
      const result = certificateList?.items?.filter(
        i => i?.handle === selectedItem?.certificateHandle,
      )
      const data = {
        ...(result
          && result?.length > 0 && {
          ...result[0],
        }),
        status: selectedItem?.status,
        endDate: selectedItem?.endDate,
        mediaItems: selectedItem?.mediaItems,
        certificateItemHandle: selectedItem?.handle,
      }
      setSelectedCertificate(data)
      setSelectedImages(selectedItem?.mediaItems as any)
      setSelectedStartDate(new Date(selectedItem?.startDate as string))
      setSelectedEndDate(new Date(selectedItem?.endDate as string))
      setIsEditMode(selectedItem?.status === Statuses.pending)
    }
  }, [selectedItem])

  const typeList = useMemo(() => {
    const result = certificateList?.items?.filter(i => i?.certificateTypeHandle === handle)
    if (result && result?.length > 0) {
      setSelectedCertificate(result[0])
    }
    return result
  }, [certificateList])

  const handleClick = () => {
    hiddenFileInput?.current?.click()
  }

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0]
    const array = [...selectedImages, fileUploaded]
    setSelectedImages(array)
  }

  const addYear = (date: Date, period: number, metric: string) => {
    const newDate = new Date(date)
    if (metric == 'Year') {
      newDate.setFullYear(newDate.getFullYear() + period)
    }
    else if (metric == 'Month') {
      newDate.setMonth(newDate.getMonth() + period)
    }
    return newDate
  }

  const isFile = (obj: unknown): obj is File => {
    return obj instanceof File
  }

  const onTapSave = () => {
    const paramItem: CertificateItemDto = {
      userHandle,
      certificateHandle: selectedCertificate?.handle,
      certificateTypeHandle: selectedCertificate?.certificateTypeHandle,
      title: selectedCertificate?.title,
      startDate: selectedStartDate.toDateString(),
      ...(selectedCertificate?.validityPeriod && {
        endDate: selectedEndDate?.toDateString(),
      }),
      ...(selectedItem
        && selectedCertificate?.status !== Statuses.approved
        && selectedCertificate?.status !== Statuses.archived && {
        handle: selectedCertificate?.certificateItemHandle,
      }),
    }
    setErrorString([])
    const requestParams: FormData = new FormData()
    if (selectedImages?.length > 0) {
      const handleArray: string[] = []
      const fileArray: HTMLInputElement[] = []
      selectedImages?.map((i: any) => {
        if (isFile(i)) {
          fileArray.push(i as any)
        }
        else {
          handleArray.push(i?.handle)
        }
      })
      fileArray?.forEach((item: any) => {
        requestParams.append('files', item)
      })
      if (fileArray?.length > 0) {
        uploadMediaMute(requestParams, {
          onSuccess: (response: UploadResponse) => {
            if (response.isSuccess) {
              const updatedParams = {
                ...paramItem,
                mediaItemHandles: [
                  ...handleArray,
                  ...(response.items?.map(i => i.handle)?.filter(Boolean) as string[]),
                ],
              }
              apiCall(updatedParams)
            }
          },
        })
      }
      else {
        const updatedParams = {
          ...paramItem,
          mediaItemHandles: handleArray,
        }
        apiCall(updatedParams)
      }
    }
    else {
      if (selectedCertificate?.isAttachmentRequired) {
        const errors = []
        errors.push('Attachments requires for this type')
        setErrorString(errors)
      }
      else {
        apiCall(paramItem)
      }
    }
  }

  const apiCall = (params: CertificateItemDto) => {
    if (selectedCertificate?.status === Statuses.pending) {
      updateCertificateItem(params, {
        onSuccess: () => {
          const data = {
            status: Statuses.approved,
            certificateHandle: selectedCertificate?.certificateItemHandle,
            certificateTypeHandle: selectedCertificate?.certificateTypeHandle,
          }
          postCertificateStatus(data, {
            onSuccess: () => {
              onCloseAlert?.()
            },
          })
        },
      })
    }
    else if (
      selectedCertificate?.status === Statuses.approved
      || selectedCertificate?.status === Statuses.archived
    ) {
      postReNewCertificate(params, {
        onSuccess: () => {
          onCloseAlert?.()
        },
      })
    }
    else {
      postCertificateItem(params, {
        onSuccess: () => {
          onCloseAlert?.()
        },
      })
    }
  }

  return (
    <CustomAlert
      onClose={() => {
        onCloseAlert?.()
      }}
    >
      {(isUploadMediaPending
        || isCertificateItemPending
        || isUpdateCertificateItemPending
        || isPostCertificateStatus
        || isPostReNewCertificate) && <Spinner />}
      {isDeleteMode && (
        <DeleteReasonsModal
          onCloseAlert={() => {
            setIsDeleteMode(false)
          }}
          onRemove={(item) => {
            const data = {
              status: Statuses.archived,
              certificateHandle: selectedCertificate?.certificateItemHandle,
              certificateTypeHandle: selectedCertificate?.certificateTypeHandle,
              archivingReason: item?.reason,
              archivingComment: item?.comment,
            }
            postCertificateStatus(data, {
              onSuccess: () => {
                onCloseAlert?.()
              },
            })
          }}
        />
      )}
      <div className="flex items-center justify-center">
        <CustomText variant="xlPoppinsSemiBold" className="flex-1">
          {selectedItem ? `${selectedItem?.title}` : t('createNew')}
        </CustomText>
        <button onClick={() => onCloseAlert?.()}>
          <CloseIcon />
        </button>
      </div>
      <div className="mt-5 flex flex-col text-left gap-4 py-5 border-y border-stroke">
        {selectedItem?.metadata?.ArchivingReason && (
          <CustomText variant="mdPoppinsRegular">
            {`Archiving reason: ${selectedItem?.metadata?.ArchivingReason}`}
          </CustomText>
        )}
        <CustomDropDown
          {...(selectedItem && !isEditMode && { disabled: true })}
          values={selectedCertificate ? [selectedCertificate] : []}
          title={t('certificateType')}
          options={typeList ?? []}
          labelField="title"
          valueField="handle"
          className="!mt-2 !mx-0"
          onChange={(item) => {
            const data = {
              ...item[0],
              status: selectedItem?.status,
              endDate: (item[0]?.validityPeriod as number) > 0 ? selectedItem?.endDate : null,
              mediaItems: selectedItem?.mediaItems,
              certificateItemHandle: selectedItem?.handle,
            }
            setSelectedCertificate(data)
          }}
        />
        <CustomDatePicker
          {...(selectedItem && !isEditMode && { disabled: true })}
          title={t('startDate')}
          value={selectedStartDate}
          className="!w-1/2"
          onChange={(value) => {
            if (value) {
              setSelectedStartDate(value)
            }
          }}
        />
        {(selectedCertificate?.endDate || (selectedCertificate?.validityPeriod as number) > 0) && (
          <CustomDatePicker
            {...(selectedItem && !isEditMode && { disabled: true })}
            title={t('endDate')}
            value={
              selectedEndDate
              || addYear(
                new Date(),
                selectedCertificate?.validityPeriod ?? 0,
                selectedCertificate?.validityPeriodMetric ?? '',
              )
            }
            minDate={selectedStartDate}
            className="!w-1/2"
            onChange={(value) => {
              if (value) {
                setSelectedEndDate(value)
              }
            }}
          />
        )}
        <div className="flex flex-col gap-2">
          <CustomText variant="mdPoppinsSemiBold">{t('files')}</CustomText>
          {selectedImages?.length > 0
          && hiddenFileInput?.current?.files
          && selectedImages?.map((i, index) => {
            return (
              <div className="flex gap-3">
                <CustomText
                  className="hover:underline"
                  variant="mdPoppinsRegular"
                  onClick={() => {
                    if (selectedCertificate?.mediaItems) {
                      window.open(
                        selectedCertificate?.mediaItems[index]?.url,
                        '_blank',
                        'noreferrer',
                      )
                    }
                  }}
                >
                  {decodeURI(i?.name)}
                </CustomText>
                {(!selectedItem || isEditMode) && (
                  <button
                    onClick={() => {
                      const array = [...selectedImages]
                      array.splice(index, 1)
                      setSelectedImages(array)
                    }}
                  >
                    <CloseIcon />
                  </button>
                )}
              </div>
            )
          })}
          {(!selectedItem || isEditMode) && (
            <CustomButton
              children={t('addAttachments')}
              backgroundColor="bg-bthDisable"
              textColor="text-textPrimary"
              className="self-start"
              style={{ paddingLeft: 20, paddingRight: 20 }}
              onClick={() => {
                handleClick()
              }}
            />
          )}
          <input
            type="file"
            onChange={handleChange}
            ref={hiddenFileInput}
            hidden
            onClick={(event: any) => (event.target.value = null)}
          />
          {errorString.length > 0 && (
            <ul className="list-disc pl-4">
              {errorString.map((i, index) => (
                <li key={index} className="text-textDanger">
                  {i}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="flex justify-end mt-5 gap-3">
        {selectedCertificate?.status === Statuses.approved
        && (!isEditMode
          ? (
              <>
                <CustomButton
                  children={t('renew')}
                  backgroundColor="bg-secondary"
                  textColor="text-textWhite"
                  className="!px-5"
                  onClick={() => {
                    setIsEditMode(true)
                  }}
                />
                <CustomButton
                  children={t('archive')}
                  backgroundColor="bg-bthDisable"
                  textColor="text-textPrimary"
                  onClick={() => {
                    setIsDeleteMode(true)
                  }}
                  className="!px-5"
                />
              </>
            )
          : (
              <CustomButton
                children={t('save')}
                backgroundColor="bg-secondary"
                textColor="text-textWhite"
                onClick={onTapSave}
                className="!px-5"
              />
            ))}
        {selectedCertificate?.status === Statuses.pending && (
          <>
            <CustomButton
              children={t('approve')}
              backgroundColor="bg-secondary"
              textColor="text-textWhite"
              className="!px-5"
              onClick={onTapSave}
            />
            <CustomButton
              children={t('delete')}
              backgroundColor="bg-primary"
              textColor="text-textWhite"
              onClick={() => {
                setIsDeleteMode(true)
              }}
              className="!px-5"
            />
          </>
        )}
        {selectedCertificate?.status === Statuses.archived
        && (!isEditMode
          ? (
              <CustomButton
                children={t('renew')}
                backgroundColor="bg-secondary"
                textColor="text-textWhite"
                onClick={() => {
                  setIsEditMode(true)
                }}
                className="!px-5"
              />
            )
          : (
              <CustomButton
                children={t('save')}
                backgroundColor="bg-secondary"
                textColor="text-textWhite"
                onClick={onTapSave}
                className="!px-5"
              />
            ))}
        {!selectedItem && (
          <CustomButton
            children={t('save')}
            backgroundColor="bg-secondary"
            textColor="text-textWhite"
            onClick={onTapSave}
            className="!px-5"
          />
        )}
      </div>
    </CustomAlert>
  )
}
