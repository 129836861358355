import { useInfiniteQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import moment from 'moment'
import { APIConst } from '@inphiz/api'
import type { ValueType } from 'rsuite/esm/Checkbox'

interface FetchCardActionProps {
  readonly actionType: string
  searchTxt?: string
  stateNames?: ValueType[]
  organizationUnitHandles?: ValueType[]
  managerHandles?: ValueType[]
  startDate?: string
  endDate?: string
}

export function useCardAction({
  actionType,
  searchTxt = '',
  stateNames = [],
  organizationUnitHandles = [],
  managerHandles = [],
  startDate,
  endDate,
}: FetchCardActionProps) {
  const result = useInfiniteQuery({
    queryKey: [QueryKey.cardList, actionType],
    queryFn: async ({ pageParam }) => {
      let url = `?ActionType=${actionType}&EntityName=${searchTxt}&Cursor=${pageParam}&Take=10`
      if (stateNames.length > 0) {
        const outputString = stateNames.map(i => `StateNames=${i || null}`).join('&')
        url = `${url}&${outputString}`
      }
      if (organizationUnitHandles.length > 0) {
        const outputString = organizationUnitHandles
          .map(i => `OrganizationUnitHandles=${i || null}`)
          .join('&')
        url = `${url}&${outputString}`
      }
      if (managerHandles.length > 0) {
        const outputString = managerHandles.map(i => `ManagerHandles=${i || null}`).join('&')
        url = `${url}&${outputString}`
      }
      if (startDate) {
        url = `${url}&startDate=${moment(startDate).format('yyyy-MM-DD')}`
      }
      if (endDate) {
        url = `${url}&endDate=${moment(endDate).format('yyyy-MM-DD')}`
      }

      const results = await APIFunctions.GET(APIConst.getActionCard + url)
      const nextCursorValue = pageParam + 10
      const nextCursor = nextCursorValue >= results?.totalCount ? undefined : nextCursorValue
      return { ...results, nextCursor }
    },
    initialPageParam: 0,
    getNextPageParam: lastPage => lastPage.nextCursor,
    staleTime: Number.POSITIVE_INFINITY,
    enabled: false,
  })
  return result
}
