import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { UserAchievementsDto } from '@inphiz-shared/shared-models/src/F_Selfleadership/user-achievements-dto'
import { DataItemApiResponse } from '@inphiz-shared/shared-models/src/Api/data-item-api-response'
import { QueryBuilder } from '@inphiz/core-utilities'

const UserAchievements = new UserAchievementsDto()
export type UserAchievementsItem = typeof UserAchievements

const AchievedDto = new DataItemApiResponse<UserAchievementsItem>()
type AchievedRoles = typeof AchievedDto

interface Params {
  userHandle?: string
}

export function useGetAchievedRoles({ userHandle }: Params) {
  return useQuery<AchievedRoles>({
    queryKey: [QueryKey.getAchievedRoles, userHandle],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.employeesPerformance.jobRolesAchieved).add(
        'userHandle',
        userHandle,
      )
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
