import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { UserJobRoleDto } from '@inphiz-shared/shared-models/src/Dto/user-job-role-dto'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { QueryBuilder } from '@inphiz/core-utilities'
import { GetUserRoleModes } from '@inphiz-shared/shared-models/src/F_Selfleadership/Constants/get-user-role-modes'

const UserJobRole = new UserJobRoleDto()
export type UserJobRoleItem = typeof UserJobRole

const JobRolesDto = new DataCollectionApiResponse<UserJobRoleItem>()
type JobRolesList = typeof JobRolesDto

interface Params {
  userHandle?: string
  mode?: string
}

export function useAllJobRoles({ userHandle, mode = GetUserRoleModes.all }: Params) {
  return useQuery<JobRolesList>({
    queryKey: [QueryKey.getAllJobRoles, userHandle, mode],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.employeesPerformance.allJobRoles)
        .add('UserHandle', userHandle)
        .add('Mode', mode)
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
