import { useTranslation } from 'react-i18next'
import { UserJobRoleStatuses } from '@inphiz-shared/shared-models/src/F_Selfleadership/Constants/user-job-role-statuses'
import { CustomText } from '../../atoms'
import { CloseIcon, InActiveIcon, LockedIcon, StarIcon } from '../../../icons'

interface Params {
  isHover?: boolean
  data?: any
}
export function PerformanceBadge({ data, isHover }: Params) {
  const { t } = useTranslation('common')

  const iconAndStatus = (status: string, badge: string | undefined, minimalExperience?: string) => {
    switch (status) {
      case UserJobRoleStatuses.locked:
        return {
          status: t('locked'),
          icon: (
            <LockedIcon
              className={`${minimalExperience && minimalExperience !== '00:00:00' ? 'fill-textDanger' : 'fill-yellow'}`}
            />
          ),
        }
      case UserJobRoleStatuses.unlocked:
        return { status: t('opportunity'), icon: <StarIcon /> }
      case UserJobRoleStatuses.inProgress:
        return { status: badge || t('inProgress'), icon: <StarIcon /> }
      case UserJobRoleStatuses.active:
        return { status: badge || t('active'), icon: <StarIcon /> }
      case UserJobRoleStatuses.suspended:
        return { status: badge || t('inactive'), icon: <InActiveIcon /> }
      case UserJobRoleStatuses.removed:
        return { status: t('removed'), icon: <InActiveIcon /> }
      default:
        return { status: '', icon: '' }
    }
  }

  return (
    <div className="flex flex-1 flex-col items-center justify-center text-center py-2">
      <div className="relative -mt-2">
        <CustomText
          variant="xsmPoppinsRegular"
          className="bg-white border rounded-full px-2 py-1 mr-1 absolute -right-6"
        >
          {iconAndStatus(data?.status, data?.badge?.text, data?.jobRole?.minimalExperience).status}
        </CustomText>
        <div className="h-16 w-16 mt-3 flex justify-center items-center">
          {isHover ? (
            <CloseIcon className="h-10 w-10" />
          ) : (
            <img
              src={
                data?.jobRole?.media?.length > 0 && data?.jobRole?.media?.[0]?.url
                  ? data?.jobRole?.media?.[0]?.url
                  : data?.mediaItems?.length > 0 && data?.mediaItems?.[0]?.url
              }
              className="rounded-full"
            />
          )}
        </div>
        {!isHover && (
          <div className="place-self-end -mt-4 -mr-2">
            {iconAndStatus(data?.status, data?.badge?.text, data?.jobRole?.minimalExperience).icon}
          </div>
        )}
      </div>
      <CustomText variant="xsmPoppinsRegular" className="mt-2">
        {data?.jobRole?.title ? data?.jobRole?.title : data?.title ?? ''}
      </CustomText>
    </div>
  )
}
