import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataItemApiResponse } from '@inphiz-shared/shared-models/src/Api/data-item-api-response'
import { UserDto } from '@inphiz-shared/shared-models/src/Dto/user-dto'
import { QueryBuilder } from '@inphiz/core-utilities'

const UserItemDto = new UserDto()
export type UserDetailsItem = typeof UserItemDto

const UserDetailsDto = new DataItemApiResponse<UserDetailsItem>()
export type UserDetails = typeof UserDetailsDto
interface UserObj extends UserDetails {
  user: UserDetailsItem
}

interface params {
  handle: string
}

export function useEmployeeDetails({ handle }: params) {
  return useQuery<UserObj>({
    queryKey: [QueryKey.getEmployeeDetails, handle],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.employeeDetails).add('handle', handle)
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
